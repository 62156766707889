import React, { useCallback } from 'react';
import ArrowIcon from 'assets/icons/button_arrow_icon.svg';
import './styles.scss';
import { IOrderButtonProps, IOrderType } from './interface';

function OrderButton({
    name,
    type,
    active,
    activate,
    changeOrder,
    sortable = false,
}: IOrderButtonProps) {
    const activateAndChangeOrder = useCallback(() => {
        if (!activate) return;
        if (active && changeOrder) {
            changeOrder();
        }
        if (!active) {
            activate();
        }
    }, [activate, active]);

    return (
        <div className='order-button'>
            {sortable && type ? (
                <button
                    type='button'
                    className='order-button__content'
                    onClick={() => activateAndChangeOrder()}
                >
                    <p className={active ? 'active' : ''}>{name}</p>
                    <img
                        src={ArrowIcon}
                        alt='Arrow Icon'
                        className={
                            type === IOrderType.Ascending && active
                                ? 'asc'
                                : 'desc'
                        }
                    />
                </button>
            ) : (
                <p className='order-button__content'>{name}</p>
            )}
        </div>
    );
}

export default OrderButton;
