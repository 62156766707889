import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeCookie, getCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { useAccount } from 'providers/AccountProvider';
import { RequireAnonymourProps } from './interface';

function RequiresAnonymous({ children }: RequireAnonymourProps) {
    const { account, fetchAccount } = useAccount();
    const navigate = useNavigate();
    const cookie = getCookie(SESSION_COOKIE_KEY);

    useEffect(() => {
        if (cookie && !account) {
            fetchAccount!((success: any) => {
                if (!success) removeCookie(SESSION_COOKIE_KEY);
            });
        } else if (account) navigate('/');
    }, [account]);

    if ((cookie && !account) || account) return null;

    return children;
}

export default RequiresAnonymous;
