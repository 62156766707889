import React, { Suspense } from 'react';
import './App.css';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FullPage from 'containers/FullPage';
import ScrollToTopOrHash from 'components/ScrollToTopOrHash';
import AccountProvider from 'providers/AccountProvider';
import { IRoute, Routes as AppRoutes } from './routes';
import configureStartup from './startup';

configureStartup();

function App() {
    return (
        <Suspense fallback={<div />}>
            <BrowserRouter>
                <ScrollToTopOrHash />
                <AccountProvider>
                    <FullPage>
                        <Routes>
                            {AppRoutes.map(({ url, component }: IRoute) => (
                                <Route
                                    key={url}
                                    path={url}
                                    element={component}
                                />
                            ))}
                        </Routes>
                    </FullPage>
                </AccountProvider>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
