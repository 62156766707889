/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { axios } from 'startup';
import ArrowIcon from 'assets/icons/occurrence_sidebar_arrow_icon.svg';
import { extensiveDate, getMonth } from 'utils/dates';
import { occurrenceStateDropdown } from 'utils/pagination';
import { IDropdownItemDTO } from 'DTOModels/dropdownItemDTO';
import { Dropdown } from 'linkwithus';
import ConfirmationModal from 'components/ConfirmationModal';
import OccurrenceConclusionModal from 'components/OccurrenceConclusionModal';
import { IOccurrenceSideBarProps } from './interface';
import { dateMonths } from '../FilterTableItem/interface';
import Carousel from '../Carousel';

function OccurrenceSideBar({
    modalVisibility,
    close,
    fetch,
    occurrence,
    showImages = true,
}: IOccurrenceSideBarProps) {
    const { t } = useTranslation();
    const [confirmationModalVisibility, setConfirmationModalVisibility] =
        useState(false);
    const [conclusionModalVisibility, setConclusionModalVisibility] =
        useState(false);
    const [creationDate, setCreadtionDate] = useState('');
    const [lastUpdateDate, setLastUpdateDate] = useState('');
    const [active, setActive] = useState(false);
    const [draw, setDraw] = useState(false);
    const [contentActive, setContentActive] = useState(false);
    const [backgroundActive, setBackgroundActive] = useState(false);
    const [editing, setEditing] = useState(false);
    const [occurrenceStates, setOccurrenceStates] = useState<
        IDropdownItemDTO[]
    >([]);
    const [currentState, setCurrentState] = useState<number>(0);
    const [stateOptions, setStateOptions] = useState<string[]>([]);

    useEffect(() => {
        if (!modalVisibility) {
            setEditing(false);
            setContentActive(false);
            setActive(false);
            setTimeout(() => {
                setBackgroundActive(false);
                setDraw(false);
            }, 200);
            return;
        }
        setDraw(true);
        setBackgroundActive(true);
        setTimeout(() => {
            setActive(true);
        }, 200);
        setTimeout(() => {
            setContentActive(true);
        }, 400);
    }, [modalVisibility]);

    useEffect(() => {
        if (occurrence && occurrence.CreationDate) {
            setCurrentState(occurrence.State);
            setCreadtionDate(
                extensiveDate(
                    occurrence.CreationDate,
                    dateMonths[Number(getMonth(occurrence.CreationDate)) - 1],
                ),
            );
            if (occurrence.LastUpdateDate) {
                setLastUpdateDate(
                    extensiveDate(
                        occurrence.LastUpdateDate,
                        dateMonths[
                            Number(getMonth(occurrence.LastUpdateDate)) - 1
                        ],
                    ),
                );
            }
        }
    }, [occurrence, modalVisibility]);

    useEffect(() => {
        setStateOptions([t('general.PENDING'), t('general.CONCLUDED')]);
        setOccurrenceStates(
            occurrenceStateDropdown([
                t('general.PENDING'),
                t('general.CONCLUDED'),
            ]),
        );
    }, []);

    const closeOccurrence = useCallback(() => {
        fetch();
        setEditing(!editing);
        close();
    }, [occurrence, currentState, editing]);

    const updateState = useCallback(() => {
        if (occurrence) {
            axios
                .put(`/occurrences/${occurrence.Id}`, {
                    state: `${currentState}`,
                })
                .then(() => {
                    fetch();
                    notification.success({
                        message: t('general.SUCCESS'),
                        description: t(
                            'general.OCCURRENCE_STATE_ALTERED_WITH_SUCCESS',
                        ),
                    });
                })
                .catch(() => {
                    notification.error({
                        message: t('general.ERROR'),
                        description: t('errors.0'),
                    });
                });
        }
    }, [occurrence, currentState, editing]);

    return (
        <div className={`occurrence-side-bar ${active ? 'active' : ''}`}>
            {draw && occurrence && (
                <div
                    className={`occurrence-side-bar__content ${
                        active ? 'active' : ''
                    }`}
                >
                    <OccurrenceConclusionModal
                        modalVisibility={conclusionModalVisibility}
                        close={() => {
                            setConclusionModalVisibility(false);
                        }}
                        updateList={closeOccurrence}
                        item={occurrence}
                    />
                    <ConfirmationModal
                        modalVisibility={confirmationModalVisibility}
                        close={() => {
                            setConfirmationModalVisibility(false);
                        }}
                        confirmation={() => {
                            if (
                                (currentState === 0 || currentState === 1) &&
                                editing &&
                                occurrence.State !== currentState
                            ) {
                                occurrence.State = currentState;
                                updateState();
                            }
                            setConfirmationModalVisibility(false);
                            setEditing(!editing);
                        }}
                        description={t(
                            'general.REOPEN_OCCURRENCE_CONFIRMATION_TEXT',
                        )}
                    />
                    <div
                        className={`occurrence-side-bar__content__header ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        <button type='button' onClick={close}>
                            <img src={ArrowIcon} alt='Arrow Icon' />
                        </button>
                        <div className='occurrence-side-bar__content__header__text'>
                            <p className='occurrence-side-bar__content__header__text__title'>
                                {t('general.OCCURRENCE')} {occurrence?.Id}
                            </p>
                            <p className='occurrence-side-bar__content__header__text__subtitle'>
                                {t('general.OCCURRENCE_DATE')}: {creationDate}
                            </p>
                        </div>
                    </div>
                    {occurrence.Occurrencephotos &&
                        showImages &&
                        occurrence.Occurrencephotos.length > 0 && (
                            <Carousel
                                elements={occurrence.Occurrencephotos}
                                className={`occurrence-side-bar__content__carousel ${
                                    contentActive ? 'active' : ''
                                }`}
                            />
                        )}
                    <p
                        className={`occurrence-side-bar__content__block-title ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        {t('general.STATE')}
                    </p>
                    <div
                        className={`occurrence-side-bar__content__block ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        {!editing ? (
                            <div
                                className={`occurrence-side-bar__content__block__state ${
                                    currentState === 0 ? 'pending' : 'concluded'
                                }`}
                            >
                                {stateOptions[currentState]}
                            </div>
                        ) : (
                            <Dropdown
                                items={occurrenceStates}
                                value={`${currentState}`}
                                onChange={(e: string) => {
                                    setCurrentState(Number(e));
                                }}
                                allowClear={false}
                                showSearch={false}
                                placeholder={t('general.STATE')}
                                className='occurrence-side-bar__content__block__state-dropdown'
                            />
                        )}
                        {showImages && (
                            <button
                                className={`occurrence-side-bar__content__block__state-button ${
                                    editing ? 'editing' : ''
                                }`}
                                onClick={() => {
                                    if (
                                        (currentState === 0 ||
                                            currentState === 1) &&
                                        editing &&
                                        occurrence.State !== currentState
                                    ) {
                                        if (currentState === 0) {
                                            setConfirmationModalVisibility(
                                                true,
                                            );
                                        }
                                        if (currentState === 1) {
                                            setConclusionModalVisibility(true);
                                        }
                                    } else {
                                        setEditing(!editing);
                                    }
                                }}
                                type='button'
                            >
                                {editing
                                    ? t('general.SAVE')
                                    : t('general.EDIT')}
                            </button>
                        )}
                    </div>
                    <p
                        className={`occurrence-side-bar__content__block-title ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        {t('form.TYPE')}
                    </p>
                    <div
                        className={`occurrence-side-bar__content__block ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        {occurrence.OccurrenceType.Name}
                    </div>
                    {occurrence.LastUpdateDate && (
                        <>
                            <p
                                className={`occurrence-side-bar__content__block-title ${
                                    contentActive ? 'active' : ''
                                }`}
                            >
                                {t('general.LAST_UPDATE')}
                            </p>
                            <div
                                className={`occurrence-side-bar__content__block ${
                                    contentActive ? 'active' : ''
                                }`}
                            >
                                {lastUpdateDate}
                            </div>
                        </>
                    )}
                    <p
                        className={`occurrence-side-bar__content__block-title ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        {t('form.ADDRESS')}
                    </p>
                    <div
                        className={`occurrence-side-bar__content__block ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        {occurrence.Address}
                    </div>
                    <p
                        className={`occurrence-side-bar__content__block-title ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        {t('form.DESCRIPTION')}
                    </p>
                    <div
                        className={`occurrence-side-bar__content__block ${
                            contentActive ? 'active' : ''
                        }`}
                    >
                        {occurrence.Description}
                    </div>
                    {occurrence.State === 1 && occurrence.AdminDescription && (
                        <>
                            <p
                                className={`occurrence-side-bar__content__block-title ${
                                    contentActive ? 'active' : ''
                                }`}
                            >
                                {t('form.CONCLUDED_DESCRIPTION')}
                            </p>
                            <div
                                className={`occurrence-side-bar__content__block last ${
                                    contentActive ? 'active' : ''
                                }`}
                            >
                                {occurrence.AdminDescription}
                            </div>
                            {occurrence.Occurrenceadminphotos &&
                                showImages &&
                                occurrence.Occurrenceadminphotos.length > 0 && (
                                    <Carousel
                                        elements={
                                            occurrence.Occurrenceadminphotos
                                        }
                                        admin
                                        className={`occurrence-side-bar__content__carousel ${
                                            contentActive ? 'active' : ''
                                        }`}
                                    />
                                )}
                        </>
                    )}
                </div>
            )}
            <button
                type='button'
                onClick={close}
                className={`occurrence-side-bar__background ${
                    backgroundActive ? 'active' : ''
                }`}
            >
                {' '}
            </button>
        </div>
    );
}

export default OccurrenceSideBar;
