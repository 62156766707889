import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { axios } from 'startup';
import { IVisibilityButtonProps } from './interface';
import './styles.scss';

function VisibilityButton({ className, item }: IVisibilityButtonProps) {
    const { t } = useTranslation();
    const [visibility, setVisibility] = useState<boolean>();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setVisibility(item.IsActive);
    }, [item, item.IsActive]);
    const updateItem = () => {
        setLoading(true);
        if (item) {
            axios
                .put(`/occurrences/type/${item.Id}`, {
                    visibility: `${!item.IsActive}`,
                })
                .then(() => {
                    // eslint-disable-next-line no-param-reassign
                    item.IsActive = !item.IsActive;
                    setVisibility(item.IsActive);
                    notification.success({
                        message: t('general.SUCCESS'),
                        description: t(
                            'general.OCCURRENCE_STATE_ALTERED_WITH_SUCCESS',
                        ),
                    });
                    setLoading(false);
                })
                .catch(() => {
                    notification.error({
                        message: t('general.ERROR'),
                        description: t('errors.0'),
                    });
                    setLoading(false);
                });
        }
    };

    return (
        <button
            disabled={loading}
            type='button'
            className={`visibility-button ${className || ''} ${
                visibility ? 'active' : ''
            }`}
            onClick={updateItem}
        >
            <div
                className={`visibility-button__content ${
                    visibility ? 'active' : ''
                }`}
            />
        </button>
    );
}

export default VisibilityButton;
