import React from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import config from 'config';
import DeleteIcon from 'assets/icons/delete_user_icon.svg';
import EditIcon from 'assets/icons/edit_list_item_icon.svg';
import { placefilterTableItemContentMotion } from './animation';
import { IPlaceFilterTableItemProps } from './interface';

function PlaceFilterTableItem({
    item,
    onDelete,
    onEdit,
}: IPlaceFilterTableItemProps) {
    const currentDate = new Date();
    return (
        <motion.div
            {...placefilterTableItemContentMotion}
            className='place-filter-table-item'
        >
            <div className='place-filter-table-item__clicable'>
                <div className='place-filter-table-item__clicable__left-field'>
                    <div className='place-filter-table-item__clicable__left-field__img'>
                        <img
                            src={`${config.API_URL}/places/images/${item.Id}?date=${currentDate}`}
                            alt='Item Img'
                        />
                    </div>
                </div>
                <div className='place-filter-table-item__clicable__left-field'>
                    <p>{item.Placelanguages[0].Name}</p>
                </div>
                <div className='place-filter-table-item__clicable__right-field'>
                    <p>{item.Placelanguages[0].Description}</p>
                    <button
                        type='button'
                        className='place-filter-table-item__clicable__right-field__edit-button'
                        onClick={onEdit}
                    >
                        <img src={EditIcon} alt='Edit Icon' />
                    </button>
                    <button
                        type='button'
                        className='place-filter-table-item__clicable__right-field__delete-button'
                        onClick={onDelete}
                    >
                        <img src={DeleteIcon} alt='Delete Icon' />
                    </button>
                </div>
            </div>
        </motion.div>
    );
}

export default PlaceFilterTableItem;
