/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'providers/AccountProvider';
import ArrowIcon from 'assets/icons/button_arrow_icon.svg';
import './styles.scss';
import { axios } from 'startup';
import config from 'config';
import { Spin, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Dropdown, SearchInput, useForm } from 'linkwithus';
import { IDropdownItemDTO } from 'DTOModels/dropdownItemDTO';
import { IAccountListItemDTO } from 'DTOModels/accountDTO';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { dropdownItems, pagination } from 'utils/pagination';
import ConfirmationModal from 'components/ConfirmationModal';
import EditAccountAccessModal from 'components/EditAccountAccessModal';
import OrderButton from './components/OrderButton';
import { IOrderType } from './components/OrderButton/interface';
import { IDeleteUserForm, IOrderFields } from './interface';
import UsersFilterTableItem from './components/UsersFilterTableItem';

function UsersFilterTable() {
    const { t } = useTranslation();
    const [confirmationModalVisibility, setConfirmationModalVisibility] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setAccount } = useAccount();
    const [totalItems, setTotalItems] = useState<IAccountListItemDTO[]>([]);
    const [itemToEdit, setItemToEdit] = useState<
        IAccountListItemDTO | undefined
    >();
    const [editModalVisibility, setEditModalVisibility] = useState(false);
    const [itemsPagination, setItemsPagination] = useState<
        IAccountListItemDTO[][]
    >([]);
    const [itemsPaginationDropdown, setItemsPaginationDropdown] = useState<
        IDropdownItemDTO[]
    >([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalNumberOfAccounts, setTotalNumberOfAccounts] = useState(0);
    const [totalPageNumber, setTotalPageNumber] = useState(5);
    const [intermediarySearchFilter, setIntermediarySearchFilter] = useState<
        string | undefined
    >();
    const [searchFilter, setSearchFilter] = useState<string | undefined>();
    const [currentOrderType, setCurrentOrderType] = useState(
        IOrderFields.CreationDate,
    );
    const [currentOrder, setCurrentOrder] = useState(IOrderType.Descending);
    const [selectedUserToDelete, setSelectedUserToDelete] = useState<number>();

    const openEditModal = () => {
        setEditModalVisibility(true);
    };

    const changeOrderType = useCallback((order: IOrderFields) => {
        setCurrentOrderType(order);
        setCurrentOrder(IOrderType.Descending);
    }, []);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const changeOrder = useCallback(() => {
        if (currentOrder === IOrderType.Ascending)
            setCurrentOrder(IOrderType.Descending);
        else {
            setCurrentOrder(IOrderType.Ascending);
        }
    }, [currentOrder]);

    const fetchList = () => {
        setLoading(true);
        // currentOrderType, currentOrder
        const currentDate = new Date();
        const orderTypeString = currentOrder
            ? `&orderType=${currentOrder}`
            : '';
        const orderFieldString = currentOrderType
            ? `&orderField=${currentOrderType}`
            : '';
        const searchString =
            searchFilter && searchFilter !== ''
                ? `&search=${searchFilter}`
                : '';
        axios
            .get(
                `${
                    config.API_URL
                }/accounts/backoffice-accounts?limit=${1}${searchString}${orderTypeString}${orderFieldString}&date=${currentDate}`,
            )
            .then(response => {
                setTotalItems(
                    response.data.Accounts.map((item: IAccountListItemDTO) => ({
                        ...item,
                    })),
                );
                setTotalNumberOfAccounts(response.data.Total);
                setCurrentPageNumber(1);
                setSelectedUserToDelete(undefined);
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    const { form, updateForm, submit } = useForm<IDeleteUserForm>({
        url: '/accounts/delete-user',
        type: 'PATCH',
        onSuccess: () => {
            setConfirmationModalVisibility(false);
            notification.success({
                message: t('general.SUCCESS'),
                description: t('general.USER_DELETE_SUCCESS'),
            });
            fetchList();
        },
        onError: (data: any, error: any) => {
            setConfirmationModalVisibility(false);
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
    });

    useEffect(() => {
        fetchList();
    }, [searchFilter, currentOrder, currentOrderType]);

    useEffect(() => {
        setItemsPagination(pagination(totalItems, totalPageNumber));
        setLoading(false);
    }, [totalItems, totalPageNumber]);

    useEffect(() => {
        setItemsPaginationDropdown(
            dropdownItems(
                totalNumberOfAccounts && totalNumberOfAccounts > 5
                    ? totalNumberOfAccounts
                    : 5,
            ),
        );
    }, [totalNumberOfAccounts]);

    // throttle set of input Search (300 miliseconds)
    useEffect(() => {
        const interval = setInterval(() => {
            if (intermediarySearchFilter === '') {
                setSearchFilter(undefined);
                return;
            }
            setSearchFilter(intermediarySearchFilter);
        }, 300);
        return () => {
            clearInterval(interval);
        };
    }, [intermediarySearchFilter]);

    useEffect(() => {
        if (selectedUserToDelete) {
            updateForm('userId', selectedUserToDelete);
        }
    }, [selectedUserToDelete]);

    return (
        <div className='users-filter-table'>
            <EditAccountAccessModal
                modalVisibility={editModalVisibility}
                close={() => {
                    setEditModalVisibility(false);
                }}
                account={itemToEdit}
                onSuccess={fetchList}
            />
            <ConfirmationModal
                modalVisibility={confirmationModalVisibility}
                close={() => {
                    setConfirmationModalVisibility(false);
                }}
                confirmation={() => {
                    // do request to soft-delete user
                    if (!form.userId) {
                        return;
                    }
                    submit();
                }}
                description={t('usersPage.CONFIRM_DELETE_USER')}
            />
            <div className='users-filter-table__header'>
                <div className='users-filter-table__header__left-column'>
                    <SearchInput
                        onChange={(e: string) => {
                            setIntermediarySearchFilter(e);
                        }}
                        value={intermediarySearchFilter}
                        placeholder={t('general.SEARCH')}
                        className='users-filter-table__header__left-column__search'
                    />
                </div>
            </div>
            <div className='users-filter-table__order'>
                <div className='users-filter-table__order__field'>
                    <OrderButton
                        name={t('general.NAME_AND_EMAIL')}
                        type={currentOrder}
                        active={currentOrderType === IOrderFields.NameAndEmail}
                        activate={() =>
                            changeOrderType(IOrderFields.NameAndEmail)
                        }
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
                <div className='users-filter-table__order__field'>
                    <OrderButton
                        name={t('general.REGISTER_DATE')}
                        type={currentOrder}
                        active={currentOrderType === IOrderFields.CreationDate}
                        activate={() =>
                            changeOrderType(IOrderFields.CreationDate)
                        }
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
                <div className='users-filter-table__order__field last'>
                    <OrderButton
                        name={t('general.ACCESS')}
                        type={currentOrder}
                        active={currentOrderType === IOrderFields.IsLimited}
                        activate={() => changeOrderType(IOrderFields.IsLimited)}
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
            </div>
            {!loading && itemsPagination && itemsPagination.length > 0 && (
                <div className='users-filter-table__items-list'>
                    {itemsPagination[currentPageNumber - 1].map(
                        (account: IAccountListItemDTO, index: number) => {
                            return (
                                <UsersFilterTableItem
                                    item={account}
                                    key={index}
                                    onClick={() => {
                                        // remove account
                                        setConfirmationModalVisibility(true);
                                        setSelectedUserToDelete(account.Id);
                                    }}
                                    onEdit={() => {
                                        // edit account
                                        setItemToEdit(account);
                                        setTimeout(() => {
                                            openEditModal();
                                        }, 0);
                                    }}
                                />
                            );
                        },
                    )}
                </div>
            )}
            {loading && (
                <div className='users-filter-table__loading'>
                    <Spin />
                </div>
            )}
            <div className='users-filter-table__footer'>
                <div className='users-filter-table__footer__left-column'>
                    <p>{t('general.SHOW')}</p>
                    <Dropdown
                        items={itemsPaginationDropdown}
                        value={`${totalPageNumber}`}
                        onChange={(e: string) => {
                            setCurrentPageNumber(1);
                            setTotalPageNumber(Number(e));
                        }}
                        allowClear={false}
                        showSearch={false}
                        placeholder={t('form.TYPE_PLACEHOLDER')}
                        className='users-filter-table__footer__left-column__dropdown'
                    />
                    <p>
                        {t('general.OF')} {totalNumberOfAccounts}{' '}
                        {t('general.RESULTS')}
                    </p>
                </div>
                <div className='users-filter-table__footer__right-column'>
                    <p>
                        {t('general.PAGE')} {currentPageNumber}{' '}
                        {t('general.OF')} {itemsPagination.length || 1}
                    </p>
                    <button
                        type='button'
                        className='users-filter-table__footer__right-column__center-button'
                        onClick={() => {
                            if (currentPageNumber === 1) return;
                            setCurrentPageNumber(currentPageNumber - 1);
                        }}
                    >
                        <img src={ArrowIcon} alt='Arrow Icon' />
                    </button>
                    <button
                        type='button'
                        onClick={() => {
                            if (
                                currentPageNumber + 1 ===
                                itemsPagination.length + 1
                            )
                                return;
                            setCurrentPageNumber(currentPageNumber + 1);
                        }}
                    >
                        <img src={ArrowIcon} alt='Arrow Icon' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UsersFilterTable;
