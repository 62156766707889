/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'providers/AccountProvider';
import ArrowIcon from 'assets/icons/button_arrow_icon.svg';
import './styles.scss';
import { axios } from 'startup';
import config from 'config';
import { Spin, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Dropdown, SearchInput, useForm } from 'linkwithus';
import { IDropdownItemDTO } from 'DTOModels/dropdownItemDTO';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { dropdownItems, pagination } from 'utils/pagination';
import ConfirmationModal from 'components/ConfirmationModal';
import { IGastronomyListItemDTO } from 'DTOModels/gastronomyDTO';
import GastronomyModal from 'components/GastronomyModal';
import { GastronomyModalMode } from 'components/GastronomyModal/interface';
import { IDeleteUserForm, IOrderFields } from './interface';
import GastronomyFilterTableItem from './components/GastronomyFilterTableItem';
import { IOrderType } from '../OrderButton/interface';
import OrderButton from '../OrderButton';

function GastronomyFilterTable() {
    const { t } = useTranslation();
    const [currentMode, setCurrentMode] = useState<GastronomyModalMode>();
    const [confirmationModalVisibility, setConfirmationModalVisibility] =
        useState(false);
    const [gastronomyModalVisibility, setGastronomyModalVisibility] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setAccount } = useAccount();
    const [totalItems, setTotalItems] = useState<IGastronomyListItemDTO[]>([]);
    const [itemsPagination, setItemsPagination] = useState<
        IGastronomyListItemDTO[][]
    >([]);
    const [itemsPaginationDropdown, setItemsPaginationDropdown] = useState<
        IDropdownItemDTO[]
    >([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);
    const [totalPageNumber, setTotalPageNumber] = useState(5);
    const [intermediarySearchFilter, setIntermediarySearchFilter] = useState<
        string | undefined
    >();
    const [searchFilter, setSearchFilter] = useState<string | undefined>();
    const [currentOrderType, setCurrentOrderType] = useState(IOrderFields.Name);
    const [currentOrder, setCurrentOrder] = useState(IOrderType.Descending);
    const [selectedItem, setSelectedItem] = useState<IGastronomyListItemDTO>();

    const changeOrderType = useCallback((order: IOrderFields) => {
        setCurrentOrderType(order);
        setCurrentOrder(IOrderType.Descending);
    }, []);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const changeOrder = useCallback(() => {
        if (currentOrder === IOrderType.Ascending)
            setCurrentOrder(IOrderType.Descending);
        else {
            setCurrentOrder(IOrderType.Ascending);
        }
    }, [currentOrder]);

    const fetchList = () => {
        setLoading(true);
        // currentOrderType, currentOrder
        const currentDate = new Date();
        const orderTypeString = currentOrder
            ? `&orderType=${currentOrder}`
            : '';
        const orderFieldString = currentOrderType
            ? `&orderField=${currentOrderType}`
            : '';
        const searchString =
            searchFilter && searchFilter !== ''
                ? `&search=${searchFilter}`
                : '';
        axios
            .get(
                `${
                    config.API_URL
                }/gastronomy?limit=${1}${searchString}${orderTypeString}${orderFieldString}&date=${currentDate}`,
            )
            .then(response => {
                setTotalItems(
                    response.data.Gastronomy.map(
                        (item: IGastronomyListItemDTO) => ({
                            ...item,
                        }),
                    ),
                );
                setTotalNumberOfItems(response.data.Total);
                setCurrentPageNumber(1);
                setSelectedItem(undefined);
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    const { form, updateForm, submit } = useForm<IDeleteUserForm>({
        url: `/gastronomy/${selectedItem ? selectedItem.Id : ''}`,
        type: 'PATCH',
        onSuccess: () => {
            setConfirmationModalVisibility(false);
            notification.success({
                message: t('general.SUCCESS'),
                description: t('general.DISH_DELETE_SUCCESS'),
            });
            fetchList();
        },
        onError: (data: any, error: any) => {
            setConfirmationModalVisibility(false);
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
    });

    useEffect(() => {
        fetchList();
    }, [searchFilter, currentOrder, currentOrderType]);

    useEffect(() => {
        setItemsPagination(pagination(totalItems, totalPageNumber));
        setLoading(false);
    }, [totalItems, totalPageNumber]);

    useEffect(() => {
        setItemsPaginationDropdown(
            dropdownItems(
                totalNumberOfItems && totalNumberOfItems > 5
                    ? totalNumberOfItems
                    : 5,
            ),
        );
    }, [totalNumberOfItems]);

    // throttle set of input Search (300 miliseconds)
    useEffect(() => {
        const interval = setInterval(() => {
            if (intermediarySearchFilter === '') {
                setSearchFilter(undefined);
                return;
            }
            setSearchFilter(intermediarySearchFilter);
        }, 300);
        return () => {
            clearInterval(interval);
        };
    }, [intermediarySearchFilter]);

    useEffect(() => {
        if (selectedItem) {
            updateForm('gastronomyId', selectedItem.Id);
        }
    }, [selectedItem]);

    return (
        <div className='gastronomy-filter-table'>
            <GastronomyModal
                modalVisibility={gastronomyModalVisibility}
                close={() => {
                    setGastronomyModalVisibility(false);
                }}
                updateList={fetchList}
                mode={currentMode}
                item={selectedItem}
            />
            <ConfirmationModal
                modalVisibility={confirmationModalVisibility}
                close={() => {
                    setConfirmationModalVisibility(false);
                }}
                confirmation={() => {
                    // do request to soft-delete dish
                    if (!form.gastronomyId) {
                        return;
                    }
                    submit();
                }}
                description={t('contentSettingsPage.CONFIRM_DELETE_GASTRONOMY')}
            />
            <div className='gastronomy-filter-table__header'>
                <div className='gastronomy-filter-table__header__left-column'>
                    <SearchInput
                        onChange={(e: string) => {
                            setIntermediarySearchFilter(e);
                        }}
                        value={intermediarySearchFilter}
                        placeholder={t('general.SEARCH')}
                        className='gastronomy-filter-table__header__left-column__search'
                    />
                </div>
                <div className='gastronomy-filter-table__header__right-column'>
                    <button
                        type='button'
                        onClick={() => {
                            setCurrentMode(GastronomyModalMode.Create);
                            setGastronomyModalVisibility(true);
                        }}
                    >
                        {t('contentSettingsPage.ADD_GASTRONOMY')}
                    </button>
                </div>
            </div>
            <div className='gastronomy-filter-table__order'>
                <div className='gastronomy-filter-table__order__field'>
                    <OrderButton
                        name={t('contentSettingsPage.IMAGE')}
                        active={currentOrderType === IOrderFields.Image}
                    />
                </div>
                <div className='gastronomy-filter-table__order__field'>
                    <OrderButton
                        name={t('contentSettingsPage.DISH_NAME')}
                        type={currentOrder}
                        active={currentOrderType === IOrderFields.Name}
                        activate={() => changeOrderType(IOrderFields.Name)}
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
                <div className='gastronomy-filter-table__order__field last'>
                    <OrderButton
                        name={t('form.LOCATION')}
                        type={currentOrder}
                        active={currentOrderType === IOrderFields.Description}
                        activate={() =>
                            changeOrderType(IOrderFields.Description)
                        }
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
            </div>
            {!loading && itemsPagination && itemsPagination.length > 0 && (
                <div className='gastronomy-filter-table__items-list'>
                    {itemsPagination[currentPageNumber - 1].map(
                        (item: IGastronomyListItemDTO, index: number) => {
                            return (
                                <GastronomyFilterTableItem
                                    item={item}
                                    key={index}
                                    onDelete={() => {
                                        // remove item
                                        setConfirmationModalVisibility(true);
                                        setSelectedItem(item);
                                    }}
                                    onEdit={() => {
                                        // open modal to edit
                                        setCurrentMode(
                                            GastronomyModalMode.Edit,
                                        );
                                        setGastronomyModalVisibility(true);
                                        setSelectedItem(item);
                                    }}
                                />
                            );
                        },
                    )}
                </div>
            )}
            {loading && (
                <div className='gastronomy-filter-table__loading'>
                    <Spin />
                </div>
            )}
            <div className='gastronomy-filter-table__footer'>
                <div className='gastronomy-filter-table__footer__left-column'>
                    <p>{t('general.SHOW')}</p>
                    <Dropdown
                        items={itemsPaginationDropdown}
                        value={`${totalPageNumber}`}
                        onChange={(e: string) => {
                            setCurrentPageNumber(1);
                            setTotalPageNumber(Number(e));
                        }}
                        allowClear={false}
                        showSearch={false}
                        placeholder={t('form.TYPE_PLACEHOLDER')}
                        className='gastronomy-filter-table__footer__left-column__dropdown'
                    />
                    <p>
                        {t('general.OF')} {totalNumberOfItems}{' '}
                        {t('general.RESULTS')}
                    </p>
                </div>
                <div className='gastronomy-filter-table__footer__right-column'>
                    <p>
                        {t('general.PAGE')} {currentPageNumber}{' '}
                        {t('general.OF')} {itemsPagination.length || 1}
                    </p>
                    <button
                        type='button'
                        className='gastronomy-filter-table__footer__right-column__center-button'
                        onClick={() => {
                            if (currentPageNumber === 1) return;
                            setCurrentPageNumber(currentPageNumber - 1);
                        }}
                    >
                        <img src={ArrowIcon} alt='Arrow Icon' />
                    </button>
                    <button
                        type='button'
                        onClick={() => {
                            if (
                                currentPageNumber + 1 ===
                                itemsPagination.length + 1
                            )
                                return;
                            setCurrentPageNumber(currentPageNumber + 1);
                        }}
                    >
                        <img src={ArrowIcon} alt='Arrow Icon' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default GastronomyFilterTable;
