import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import SiteButton from 'components/SiteButton';
import { PasswordInput, useForm } from 'linkwithus';
import { forgotPasswordFormMotion } from 'containers/ForgotPasswordPage/animation';
import { IForgotPasswordStepProps } from 'containers/ForgotPasswordPage/interface';
import { passwordRegex } from 'utils/regex';
import { IForgotPasswordStep3FormProps } from './interface';

function Step3({ token, code }: IForgotPasswordStepProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const INITIAL_FORM = {
        code,
        token,
    };

    const { register, registerSubmit, form } =
        useForm<IForgotPasswordStep3FormProps>({
            url: 'accounts/forgot-password/reset-password',
            initialForm: INITIAL_FORM,
            onSuccess: () => {
                notification.success({
                    message: t(`general.SUCCESS`),
                    description: t('forgotPasswordPage.RESET_PASSWORD_SUCCESS'),
                });
                setTimeout(() => {
                    navigate('/login');
                }, 500);
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        });

    const { password } = form;

    return (
        <motion.div
            {...forgotPasswordFormMotion}
            className='forgot-password-page__form'
        >
            <p className='forgot-password-page__form__question'>
                {t('forgotPasswordPage.FORGOT_PASSWORD_QUESTION')}
            </p>
            <p className='forgot-password-page__form__instruction'>
                {t('forgotPasswordPage.INSERT_NEW_PASSWORD')}
            </p>
            <p className='forgot-password-page__form__label'>
                {t('form.PASSWORD')}
            </p>
            <PasswordInput
                {...register('password', {
                    required: true,
                    regex: passwordRegex,
                    resolveError: (val, error) => {
                        if (error.type === 'regex') {
                            return `${t('form.PASSWORD')} ${t(
                                'form.PASSWORD_FORMAT',
                            )}`;
                        }
                        return `${t('form.PASSWORD')} ${t(
                            'form.REQUIRED_FIELD',
                        )}`;
                    },
                })}
                className='forgot-password-page__form__field'
            />
            <p className='forgot-password-page__form__label'>
                {t('form.CONFIRM_PASSWORD')}
            </p>
            <PasswordInput
                {...register('confirmPassword', {
                    required: true,
                    isValid: v => v === password,
                    resolveError: (v, err) =>
                        err.type === 'custom-validation'
                            ? `${t('form.PASSWORD')} ${t(
                                  'form.REQUIRED_FIELD',
                              )}`
                            : t('form.NON_MATCHING_PASSWORDS'),
                })}
                className='forgot-password-page__form__field'
            />
            <SiteButton
                text={t('general.SEND')}
                type='primary'
                className='uppercase full-width forgot-password-page__form__step3-button'
                {...registerSubmit()}
            />
        </motion.div>
    );
}

export default Step3;
