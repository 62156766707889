import { IRentACarListItemDTO } from 'DTOModels/rentACarDTO';

export enum RentACarModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface IRentACarModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: RentACarModalMode;
    item?: IRentACarListItemDTO;
    updateList: () => void;
}

export interface IRentACarForm {
    name?: string;
    location?: string;
    contact?: string;
    photo?: any;
}
