import React, { useEffect, useState } from 'react';
import IncreaseIcon from 'assets/icons/increase_percentage_arrow_icon.svg';
import DecreaseIcon from 'assets/icons/decrease_percentage_arrow_icon.svg';
import { ISummaryProps } from './interface';
import './styles.scss';

function Summary({ title, currentValue, pastValue }: ISummaryProps) {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if (!pastValue) {
            setPercentage(0);
        } else {
            setPercentage(
                Math.round(((currentValue - pastValue) / pastValue) * 100),
            );
        }
    }, [currentValue, pastValue]);

    return (
        <div className='summary'>
            <p className='summary__title'>{title}</p>
            <div className='summary__values'>
                <p className='summary__values__current'>{currentValue}</p>
                {pastValue > 0 && (
                    <p
                        className={`summary__values__percentage ${
                            percentage > 0 ? 'increase' : 'decrease'
                        }`}
                    >
                        <img
                            src={percentage > 0 ? IncreaseIcon : DecreaseIcon}
                            alt='Percentage Icon'
                        />
                        <span>
                            {percentage > 0 ? '+' : ''}
                            {percentage}%
                        </span>
                    </p>
                )}
            </div>
        </div>
    );
}

export default Summary;
