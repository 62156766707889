export function countAndGroupByMonth(array: any[]) {
    const statistics = new Array<number>(12).fill(0);

    array.forEach(element => {
        const elementMonth = element.month - 1;
        statistics[elementMonth] += 1;
    });

    return statistics;
}

export function countAndGroupByWeek(array: any[], weekDays: number[]) {
    const statistics = new Array<number>(8).fill(0);

    array.forEach(element => {
        const elementDay = element.day;
        const weekIndex = weekDays.indexOf(elementDay);
        if (weekIndex >= 0) {
            statistics[weekIndex] += 1;
        }
    });

    /*
    array.forEach(element => {
        const elementDay = element.day;
        statistics[elementMonth] += 1;
    });
    */

    return statistics;
}
