import { IPlaceListItemDTO } from 'DTOModels/placeDTO';

export enum PlaceModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface IPlaceModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: PlaceModalMode;
    item?: IPlaceListItemDTO;
    updateList: () => void;
}

export interface IPlaceForm {
    name?: string;
    description?: string;
    photo?: any;
}
