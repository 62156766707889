import { IAccommodationListItemDTO } from 'DTOModels/accommodationDTO';

export enum AccommodationModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface IAccommodationModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: AccommodationModalMode;
    item?: IAccommodationListItemDTO;
    updateList: () => void;
}

export interface IAccommodationForm {
    name?: string;
    location?: string;
    contact?: string;
    photo?: any;
}
