export enum IOrderFields {
    Name = 'Name',
    Description = 'Description',
    Image = 'Image',
}

export interface IDeleteEntityForm {
    entityId?: number;
}

export interface ICountyEntityFilterTableProps {
    url: string;
}
