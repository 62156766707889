import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'providers/AccountProvider';
import { usersPageContentMotion } from './animation';
import UsersFilterTable from './components/UsersFilterTable';
import InvitesFilterTable from './components/InvitesFilterTable';

function UsersPage() {
    const { account } = useAccount();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        if (account && account.isLimited) {
            navigate('/');
        }
    }, [account]);

    return (
        <motion.div {...usersPageContentMotion} className='users-page'>
            <div className='users-page__header'>
                <button
                    type='button'
                    className={`users-page__header__tab ${
                        activeTab === 1 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(1)}
                >
                    {t('usersPage.ACCOUNTS')}
                </button>
                <button
                    type='button'
                    className={`users-page__header__tab ${
                        activeTab === 2 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(2)}
                >
                    {t('usersPage.INVITES')}
                </button>
                <div className='users-page__header__filler' />
            </div>
            <div className='users-page__container'>
                {activeTab === 1 && (
                    <motion.div {...usersPageContentMotion}>
                        <UsersFilterTable />
                    </motion.div>
                )}
                {activeTab === 2 && (
                    <motion.div {...usersPageContentMotion}>
                        <InvitesFilterTable />
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
}

export default UsersPage;
