import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import FilterTable from 'components/FilterTable';
import { occurrencesPageContentMotion } from './animation';

function OccurrencesPage() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);

    return (
        <motion.div
            {...occurrencesPageContentMotion}
            className='occurrences-page'
        >
            <div className='occurrences-page__header'>
                <button
                    type='button'
                    className={`occurrences-page__header__tab ${
                        activeTab === 1 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(1)}
                >
                    {t('general.CIRCULATING')}
                </button>
                <button
                    type='button'
                    className={`occurrences-page__header__tab ${
                        activeTab === 2 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(2)}
                >
                    {t('general.ARCHIVED')}
                </button>
                <div className='occurrences-page__header__filler' />
            </div>
            <div className='users-page__container'>
                {activeTab === 1 && (
                    <motion.div {...occurrencesPageContentMotion}>
                        <FilterTable />
                    </motion.div>
                )}
                {activeTab === 2 && (
                    <motion.div {...occurrencesPageContentMotion}>
                        <FilterTable historyPage />
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
}

export default OccurrencesPage;
