import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopOrHash() {
    const { hash, pathname } = useLocation();

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        if (hash) {
            timer = setTimeout(() => {
                const elementToScroll = document.getElementById(
                    hash?.replace('#', ''),
                );
                if (!elementToScroll) {
                    window.scrollTo(0, 0);
                    return;
                }
                window.scrollTo({
                    top: elementToScroll.offsetTop,
                    behavior: 'smooth',
                });
            }, 0);
        } else {
            window.scrollTo(0, 0);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [hash, pathname]);

    return null;
}

export default ScrollToTopOrHash;
