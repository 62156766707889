import React from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import DeleteIcon from 'assets/icons/delete_user_icon.svg';
import EditIcon from 'assets/icons/edit_list_item_icon.svg';
import VisibilityButton from 'components/VisibilityButton';
import { occurrenceTypefilterTableItemContentMotion } from './animation';
import { IOccurrenceTypeFilterTableItemProps } from './interface';

function OccurrenceTypeFilterTableItem({
    item,
    onDelete,
    onEdit,
}: IOccurrenceTypeFilterTableItemProps) {
    return (
        <motion.div
            {...occurrenceTypefilterTableItemContentMotion}
            className='occurrence-type-filter-table-item'
        >
            <div className='occurrence-type-filter-table-item__clicable'>
                <div className='occurrence-type-filter-table-item__clicable__left-field'>
                    <p>{item.Name}</p>
                </div>
                <div className='occurrence-type-filter-table-item__clicable__right-field'>
                    <VisibilityButton item={item} />
                    <button
                        type='button'
                        className='occurrence-type-filter-table-item__clicable__right-field__edit-button'
                        onClick={onEdit}
                    >
                        <img src={EditIcon} alt='Edit Icon' />
                    </button>
                    <button
                        type='button'
                        className='occurrence-type-filter-table-item__clicable__right-field__delete-button'
                        onClick={onDelete}
                    >
                        <img src={DeleteIcon} alt='Delete Icon' />
                    </button>
                </div>
            </div>
        </motion.div>
    );
}

export default OccurrenceTypeFilterTableItem;
