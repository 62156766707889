export const userPopoverMotion = {
    initial: { opacity: 0, x: 0 },
    animate: {
        opacity: [0, 1],
        x: [0, 20],
        transition: {
            duration: 0.3,
            ease: 'easeInOut',
        },
    },
};
