import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { Spin, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { axios } from 'startup';
import { useAccount } from 'providers/AccountProvider';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import SiteButton from 'components/SiteButton';
import { IPresidentDTO } from 'DTOModels/presidentDTO';
import EditCurrentPresidentModal from '../EditCurrentPresidentModal';
import { ModalMode } from '../EditCurrentPresidentModal/interface';

function CurrentPresident() {
    const { t } = useTranslation();
    const currentDate = new Date();
    const { setAccount } = useAccount();
    const navigate = useNavigate();
    const [item, setItem] = useState<IPresidentDTO | undefined>();
    const [editModalVisibility, setEditModalVisibility] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Edit);

    const closeEditModal = () => {
        setEditModalVisibility(false);
    };

    const openEditModal = () => {
        setModalMode(ModalMode.Edit);
        setEditModalVisibility(true);
    };

    const openCreateModal = () => {
        setModalMode(ModalMode.Create);
        setEditModalVisibility(true);
    };

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetch = () => {
        axios
            .get(
                `${config.API_URL}/presidents?currentPresident=true&orderField=Id&orderType=ASC&date=${currentDate}`,
            )
            .then(response => {
                if (
                    !response.data.Presidents ||
                    response.data.Presidents.length === 0
                )
                    return undefined;
                setItem(response.data.Presidents[0]);
                return undefined;
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div className='current-president'>
            <div className='current-president__header'>
                {item && (
                    <SiteButton
                        text={t('countyPage.EDIT_PRESIDENT')}
                        type='primary'
                        onClick={openEditModal}
                    />
                )}
                <SiteButton
                    text={t('countyPage.NEW_PRESIDENT')}
                    type='primary'
                    onClick={openCreateModal}
                />
            </div>
            {item ? (
                <div className='current-president__content'>
                    <EditCurrentPresidentModal
                        modalVisibility={editModalVisibility}
                        close={closeEditModal}
                        updateList={fetch}
                        item={item}
                        mode={modalMode}
                    />
                    <div className='current-president__content__entity'>
                        <img
                            src={`${config.API_URL}/presidents/images/${item.Id}?currentDate=${currentDate}`}
                            alt='Entity Img'
                        />
                        <div className='current-president__content__entity__title'>
                            {item.Name}
                        </div>
                    </div>
                    <div className='current-president__content__description'>
                        {item.Info &&
                            item.Info.split('\r\n').map(
                                (paragraph: string, index: number) => {
                                    return (
                                        <div
                                            className='current-president__content__description__content'
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={index}
                                        >
                                            {paragraph}
                                        </div>
                                    );
                                },
                            )}
                    </div>
                </div>
            ) : (
                <div className='current-president__empty'>
                    <Spin />
                </div>
            )}
        </div>
    );
}

export default CurrentPresident;
