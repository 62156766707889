export interface IInviteUsersModalProps {
    modalVisibility: boolean;
    close: () => void;
    onSuccess: () => void;
}

export enum IAccountInviteAccessEnum {
    Limited = 'Limited_Access',
    Full = 'Full_Access',
}

export interface IAccountInvite {
    email: string;
    access: IAccountInviteAccessEnum;
}

export interface IInviteForm {
    accounts: IAccountInvite[];
}
