import { ITravelAgencyListItemDTO } from 'DTOModels/travelAgencyDTO';

export enum TravelAgencyModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface ITravelAgencyModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: TravelAgencyModalMode;
    item?: ITravelAgencyListItemDTO;
    updateList: () => void;
}

export interface ITravelAgencyForm {
    name?: string;
    location?: string;
    contact?: string;
    photo?: any;
}
