import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Logo from 'assets/logos/municipio_logo.png';
import './styles.scss';
import { forgotPasswordFormMotion } from './animation';
import Step1 from './components/Step1';
import Step3 from './components/Step3';
import Step2 from './components/Step2';

function ForgotPasswordPage() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [code, setCode] = useState('    ');

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };
    const { t } = useTranslation();

    return (
        <div className='forgot-password-page'>
            <motion.div
                {...forgotPasswordFormMotion}
                className='forgot-password-page__header'
            >
                <img src={Logo} alt='County Logo' />
                <p className='forgot-password-page__header__medium spacer uppercase'>
                    {t('general.COUNTY_OF')}
                </p>
                <p className='forgot-password-page__header__bold uppercase'>
                    {t('general.COUNTY')}
                </p>
                <p className='forgot-password-page__header__medium'>
                    {t('general.ISLAND')}
                </p>
            </motion.div>
            {step === 1 && (
                <Step1
                    nextStep={nextStep}
                    previousStep={previousStep}
                    token={token}
                    setToken={setToken}
                    code={code}
                    setCode={setCode}
                    email={email}
                    setEmail={setEmail}
                />
            )}
            {step === 2 && (
                <Step2
                    nextStep={nextStep}
                    previousStep={previousStep}
                    token={token}
                    setToken={setToken}
                    code={code}
                    setCode={setCode}
                    email={email}
                    setEmail={setEmail}
                />
            )}
            {step === 3 && (
                <Step3
                    nextStep={nextStep}
                    previousStep={previousStep}
                    token={token}
                    setToken={setToken}
                    code={code}
                    setCode={setCode}
                    email={email}
                    setEmail={setEmail}
                />
            )}
        </div>
    );
}

export default ForgotPasswordPage;
