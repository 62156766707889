import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'providers/AccountProvider';
import { contentSettingsPageContentMotion } from './animation';
import GastronomyFilterTable from './components/GastronomyFilterTable';
import AccommodationFilterTable from './components/AccommodationFilterTable';
import OccurrenceTypeFilterTable from './components/OccurrenceTypeFilterTable';
import EventFilterTable from './components/EventFilterTable';
import RentACarFilterTable from './components/RentACarFilterTable';
import TravelAgencyFilterTable from './components/TravelAgencyFilterTable';
import ContactSection from './components/ContactSection';
import PlaceFilterTable from './components/PlaceFilterTable';

function ContentSettingsPage() {
    const { t } = useTranslation();
    const { account } = useAccount();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        if (account && account.isLimited) {
            navigate('/');
        }
    }, [account]);

    return (
        <motion.div
            {...contentSettingsPageContentMotion}
            className='content-settings-page'
        >
            <div className='content-settings-page__header'>
                <button
                    type='button'
                    className={`content-settings-page__header__tab ${
                        activeTab === 0 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(0)}
                >
                    {t('general.WARNINGS')}
                </button>
                <button
                    type='button'
                    className={`content-settings-page__header__tab ${
                        activeTab === 1 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(1)}
                >
                    {t('general.GASTRONOMY')}
                </button>
                <button
                    type='button'
                    className={`content-settings-page__header__tab ${
                        activeTab === 2 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(2)}
                >
                    {t('general.ACCOMMODATION')}
                </button>
                <button
                    type='button'
                    className={`content-settings-page__header__tab ${
                        activeTab === 3 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(3)}
                >
                    {t('general.RENT')}
                </button>
                <button
                    type='button'
                    className={`content-settings-page__header__tab ${
                        activeTab === 4 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(4)}
                >
                    {t('general.TRAVEL')}
                </button>
                <button
                    type='button'
                    className={`content-settings-page__header__tab ${
                        activeTab === 5 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(5)}
                >
                    {t('general.PLACES')}
                </button>
                <button
                    type='button'
                    className={`content-settings-page__header__tab ${
                        activeTab === 6 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(6)}
                >
                    {t('occurrencesPage.TITLE')}
                </button>
                <button
                    type='button'
                    className={`content-settings-page__header__tab ${
                        activeTab === 7 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(7)}
                >
                    {t('general.CONTACTS')}
                </button>
                <div className='content-settings-page__header__filler' />
            </div>
            <div className='content-settings-page__container'>
                {activeTab === 0 && (
                    <motion.div {...contentSettingsPageContentMotion}>
                        <EventFilterTable />
                    </motion.div>
                )}
                {activeTab === 1 && (
                    <motion.div {...contentSettingsPageContentMotion}>
                        <GastronomyFilterTable />
                    </motion.div>
                )}
                {activeTab === 2 && (
                    <motion.div {...contentSettingsPageContentMotion}>
                        <AccommodationFilterTable />
                    </motion.div>
                )}
                {activeTab === 3 && (
                    <motion.div {...contentSettingsPageContentMotion}>
                        <RentACarFilterTable />
                    </motion.div>
                )}
                {activeTab === 4 && (
                    <motion.div {...contentSettingsPageContentMotion}>
                        <TravelAgencyFilterTable />
                    </motion.div>
                )}
                {activeTab === 5 && (
                    <motion.div {...contentSettingsPageContentMotion}>
                        <PlaceFilterTable />
                    </motion.div>
                )}
                {activeTab === 6 && (
                    <motion.div {...contentSettingsPageContentMotion}>
                        <OccurrenceTypeFilterTable />
                    </motion.div>
                )}
                {activeTab === 7 && (
                    <motion.div {...contentSettingsPageContentMotion}>
                        <ContactSection />
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
}

export default ContentSettingsPage;
