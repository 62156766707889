import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { MenuTabProps } from './interface';

function Tab({ children, className, url }: MenuTabProps) {
    const navigate = useNavigate();
    return (
        <div className={`tab ${className || ''}`}>
            <button
                type='button'
                onClick={() => {
                    navigate(`${url}`);
                }}
            >
                {children}
            </button>
            <div className={`tab__marker ${className || ''}`} />
        </div>
    );
}

export default Tab;
