import React from 'react';
import Logo from 'assets/logos/municipio_logo.png';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'providers/AccountProvider';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard_icon.svg';
import { ReactComponent as Occurrences } from 'assets/icons/occurrences_icon.svg';
import { ReactComponent as County } from 'assets/icons/county_icon.svg';
import { ReactComponent as Users } from 'assets/icons/users_icon.svg';
import { ReactComponent as ContentSettings } from 'assets/icons/content_settings_icon.svg';
import './styles.scss';
import { MenuProps } from './interface';
import Tab from './components/Tab';
import UserPopover from './components/UserPopover';

function Menu({ children, tab = -1 }: MenuProps) {
    const navigate = useNavigate();
    const { account } = useAccount();
    return (
        <div className='menu'>
            <div className='menu__content'>
                <div className='menu__content__tabs'>
                    <button
                        type='button'
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <img src={Logo} alt='Logo' />
                    </button>
                    <Tab url='/' className={`${tab === 1 ? 'active' : ''}`}>
                        <Dashboard />
                    </Tab>
                    <Tab
                        url='/occurrences'
                        className={`${tab === 2 ? 'active' : ''}`}
                    >
                        <Occurrences />
                    </Tab>
                    {account && !account.isLimited && (
                        <>
                            <Tab
                                url='/county'
                                className={`${tab === 3 ? 'active' : ''}`}
                            >
                                <County />
                            </Tab>
                            <Tab
                                url='/users'
                                className={`${tab === 4 ? 'active' : ''}`}
                            >
                                <Users />
                            </Tab>
                            <Tab
                                url='/content-settings'
                                className={`${tab === 5 ? 'active' : ''}`}
                            >
                                <ContentSettings />
                            </Tab>
                        </>
                    )}
                </div>
                <UserPopover />
            </div>
            <div className='menu__page-container'>{children}</div>
        </div>
    );
}

export default Menu;
