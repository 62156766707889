import React from 'react';
import { Button } from 'linkwithus';
import { ISiteButtonProps } from './interface';
import './styles.scss';

function SiteButton({
    text,
    type,
    href,
    onClick,
    target,
    loading,
    disabled,
    className,
    icon,
}: ISiteButtonProps) {
    return (
        <Button
            className={`component site-button occurrence-button ${className}`}
            type={type}
            href={href}
            onClick={() => onClick && onClick()}
            target={target}
            loading={loading}
            disabled={disabled}
        >
            <div className='site-button__text'>
                {icon}
                <p>{text}</p>
            </div>
        </Button>
    );
}

export default SiteButton;
