import React, { useEffect } from 'react';
import { Dropdown, Input, Modal, useForm } from 'linkwithus';
import './styles.scss';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SiteButton from 'components/SiteButton';
import { IAccountInviteAccessEnum } from 'components/InviteUsersModal/interface';
import {
    IEditAccountAccessForm,
    IEditAccountAccessModalProps,
} from './interface';

function EditAccountAccessModal({
    modalVisibility,
    close,
    onSuccess,
    account,
}: IEditAccountAccessModalProps) {
    const { t } = useTranslation();

    const { registerSubmit, updateForm, register } =
        useForm<IEditAccountAccessForm>({
            url: `/accounts/access/${account?.Id}`,
            type: 'PATCH',
            onSuccess: () => {
                notification.success({
                    message: t('general.SUCCESS'),
                    description: t(
                        'general.ACCOUNT_ACCESS_ALTERED_WITH_SUCCESS',
                    ),
                });
                onSuccess();
                setTimeout(() => {
                    close();
                }, 100);
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        });

    useEffect(() => {
        if (!account) return undefined;
        updateForm('email', account.Email);
        updateForm(
            'access',
            account.IsLimited
                ? IAccountInviteAccessEnum.Limited
                : IAccountInviteAccessEnum.Full,
        );
        return undefined;
    }, [modalVisibility, account]);

    return (
        <Modal
            visible={modalVisibility}
            close={close}
            className='edit-account-access-modal'
            width={410}
        >
            {account && (
                <div className='edit-account-access-modal__content'>
                    <p className='edit-account-access-modal__content__text'>
                        {t('usersPage.ALTER_ACCESS_MODAL_TEXT')}
                    </p>
                    <div className='edit-account-access-modal__content__invite-info'>
                        <p>{t('general.ACCESS_TO_THE_PLATAFORM')}</p>
                        <Dropdown
                            items={[
                                {
                                    id: IAccountInviteAccessEnum.Limited,
                                    text: t('general.LIMITED_ACCESS'),
                                },
                                {
                                    id: IAccountInviteAccessEnum.Full,
                                    text: t('general.FULL_ACCESS'),
                                },
                            ]}
                            {...register('access', {
                                required: true,
                                resolveError: () => t('form.NAME'),
                            })}
                            onChange={(value: string) => {
                                updateForm(
                                    'access',
                                    value as IAccountInviteAccessEnum,
                                );
                            }}
                            placeholder={t('general.ACCESS')}
                            showSearch={false}
                        />
                        <p>{t('form.EMAIL')}</p>
                        <Input
                            {...register('email')}
                            disabled
                            placeholder={t('form.EMAIL')}
                        />
                    </div>
                    <div className='edit-account-access-modal__content__footer'>
                        <SiteButton
                            text={t('general.CANCEL')}
                            type='primary'
                            className='edit-account-access-modal__content__footer__button grey'
                            onClick={close}
                        />
                        <SiteButton
                            text={t('form.SAVE_CHANGES')}
                            type='primary'
                            className='edit-account-access-modal__content__footer__button'
                            {...registerSubmit()}
                        />
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default EditAccountAccessModal;
