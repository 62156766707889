import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useAccount } from 'providers/AccountProvider';
import { IPageContentContainerProps } from './interface';
import './styles.scss';
import {
    pageContentContainerBodyMotion,
    pageContentContainerTitleMotion,
} from './animation';

function PageContentContainer({
    children,
    translationKey,
    greetUser = false,
}: IPageContentContainerProps) {
    const { t } = useTranslation();
    const { account } = useAccount();

    return (
        <div className='page-content-container'>
            <motion.p
                {...pageContentContainerTitleMotion}
                className='page-content-container__title'
            >
                {t(`${translationKey}.TITLE`)}
            </motion.p>
            {greetUser && (
                <motion.p
                    {...pageContentContainerTitleMotion}
                    className='page-content-container__sub-title'
                >
                    {t('general.GREETING')} {account?.name}
                </motion.p>
            )}
            <motion.div
                className={`page-content-container__page ${
                    greetUser ? 'greet-user' : ''
                }`}
                {...pageContentContainerBodyMotion}
            >
                {children}
            </motion.div>
        </div>
    );
}

export default PageContentContainer;
