import React from 'react';
import { Modal } from 'linkwithus';
import { ReactComponent as CloseIcon } from 'assets/icons/cross_icon.svg';
import './styles.scss';
import { IImageModalProps } from './interface';

function ImageModal({ imageUrl, modalVisibility, close }: IImageModalProps) {
    return (
        <Modal
            visible={modalVisibility}
            close={close}
            noStripe
            className='image-modal'
        >
            <div className='image-modal__content'>
                <img src={imageUrl} alt='Occurrence Img' />
                <button
                    type='button'
                    onClick={() => close()}
                    className='image-modal__content__button'
                >
                    <CloseIcon />
                </button>
            </div>
        </Modal>
    );
}

export default ImageModal;
