import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { axios } from 'startup';
import Logo from 'assets/logos/municipio_logo.png';
import './styles.scss';
import SiteButton from 'components/SiteButton';
import {
    Checkbox,
    Input,
    PasswordInput,
    PhoneInput,
    useForm,
} from 'linkwithus';
import { phoneRegex } from 'utils/regex';
import { ISignUpForm } from './interface';
import { signUpFormMotion } from './animation';

function SignUpPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [remember, setRemember] = useState(false);
    const { token } = useParams();
    const signUpSuccess = () => {
        notification.success({
            message: t('general.SUCCESS'),
            description: t('signUpPage.SIGN_UP_SUCCESS'),
        });
        setTimeout(() => {
            navigate('/login');
        }, 500);
    };

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('errors.-26'),
        });
    }, []);

    const { register, registerSubmit, updateForm, form } = useForm<ISignUpForm>(
        {
            url: '/invites/signup',
            onSuccess: () => {
                signUpSuccess();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        },
    );

    useEffect(() => {
        if (token) {
            axios
                .get(`/invites/email/${token}`)
                .then((response: any) => {
                    updateForm('email', response.data.Email);
                })
                .catch(() => {
                    // force logout
                    logout();
                });
            updateForm('token', token);
            return;
        }
        logout();
    }, []);

    const { password } = form;

    return (
        <div className='sign-up-page'>
            <motion.div {...signUpFormMotion} className='sign-up-page__header'>
                <img src={Logo} alt='County Logo' />
                <p className='sign-up-page__header__medium spacer uppercase'>
                    {t('general.COUNTY_OF')}
                </p>
                <p className='sign-up-page__header__bold uppercase'>
                    {t('general.COUNTY')}
                </p>
                <p className='sign-up-page__header__medium'>
                    {t('general.ISLAND')}
                </p>
            </motion.div>
            <motion.div {...signUpFormMotion} className='sign-up-page__form'>
                <p className='sign-up-page__form__label'>{t('form.NAME')}</p>
                <Input
                    {...register('name', {
                        required: true,
                        resolveError: () =>
                            `${t('form.NAME')} ${t('form.REQUIRED_FIELD')}`,
                    })}
                    className='sign-up-page__form__field'
                />
                <p className='sign-up-page__form__label'>{t('form.CONTACT')}</p>
                <PhoneInput
                    {...register('contact', {
                        required: true,
                        regex: phoneRegex,
                        resolveError: (val, error) => {
                            if (error.type === 'regex') {
                                return `${t('general.THE')} ${t(
                                    'form.CONTACT',
                                )} ${t('form.FORMAT_INVALID')}`;
                            }
                            return `${t('form.CONTACT')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='sign-up-page__form__field'
                />
                <p className='sign-up-page__form__label'>{t('form.EMAIL')}</p>
                <Input
                    onChange={() => {
                        // Not allowed to edit
                    }}
                    value={form.email}
                    disabled
                    allowClear={false}
                    className='sign-up-page__form__field'
                />
                <p className='sign-up-page__form__label'>
                    {t('form.PASSWORD')}
                </p>
                <PasswordInput
                    {...register('password', {
                        required: true,
                        resolveError: () => {
                            return `${t('form.PASSWORD')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='sign-up-page__form__field'
                />
                <p className='sign-up-page__form__label'>
                    {t('form.CONFIRM_PASSWORD')}
                </p>
                <PasswordInput
                    {...register('confirmPassword', {
                        required: true,
                        isValid: v => v === password,
                        resolveError: (v, err) =>
                            err.type === 'custom-validation'
                                ? `${t('form.PASSWORD')} ${t(
                                      'form.REQUIRED_FIELD',
                                  )}`
                                : t('form.NON_MATCHING_PASSWORDS'),
                    })}
                    className='sign-up-page__form__field'
                />
                <div className='sign-up-page__form__remember-section'>
                    <Checkbox
                        value={remember}
                        onChange={() => setRemember(!remember)}
                    >
                        <p>{t('general.REMEMBER')}</p>
                    </Checkbox>
                    {/* 
                    <Link
                        to='/forgot-password'
                        className='sign-up-page__form__remember-section__link'
                    >
                        <span>{t('general.FORGOT_PASSWORD')}</span>
                    </Link>
                    */}
                </div>
                <SiteButton
                    text={t('signUpPage.CREATE_ACCOUNT')}
                    type='primary'
                    className='uppercase full-width sign-up-page__button'
                    {...registerSubmit()}
                />
            </motion.div>
        </div>
    );
}

export default SignUpPage;
