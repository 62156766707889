/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { DatePicker, Input, Modal, useForm } from 'linkwithus';
import './styles.scss';
import { motion } from 'framer-motion';
import config from 'config';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SiteButton from 'components/SiteButton';
import FileUploader from 'components/FileUploader';
import moment from 'moment';
import {
    ModalMode,
    IEditExPresidentForm,
    IEditExPresidentModalProps,
} from './interface';
import { contentSettingsModalMotion } from './animation';

function EditExPresidentModal({
    modalVisibility,
    close,
    item,
    mode = ModalMode.Edit,
    updateList,
}: IEditExPresidentModalProps) {
    const { t } = useTranslation();
    const [imageUrl, setImageUrl] = useState<string>();
    const [imageVisibility, setImageVisibility] = useState(false);

    const { register, form, submit, updateForm, setForm } =
        useForm<IEditExPresidentForm>({
            url: `/presidents/${
                mode === ModalMode.Edit && item ? item.Id : ''
            }`,
            type: mode === ModalMode.Edit ? 'PUT' : 'POST',
            onSuccess: () => {
                notification.success({
                    message: t('general.SUCCESS'),
                    description: t(
                        `countyPage.${
                            mode === ModalMode.Edit
                                ? 'INFO_CHANGED_WITH_SUCESS'
                                : 'EX_PRESIDENT_CREATED_WITH_SUCCESS'
                        }`,
                    ),
                });
                updateList();
                close();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
                updateList();
                close();
            },
            resolveData(rawForm) {
                const data = new FormData();
                Object.keys(rawForm).forEach(key => {
                    if (key === 'photo') {
                        rawForm.photo.forEach((f: any) => {
                            data.append('photo', f as any);
                        });
                    } else
                        data.append(
                            key,
                            rawForm[key as keyof IEditExPresidentForm] as any,
                        );
                });
                return data;
            },
        });

    useEffect(() => {
        if (modalVisibility && mode === ModalMode.Edit && !item) {
            close();
        }
        if (modalVisibility && mode === ModalMode.Edit && item) {
            const currentDate = new Date();
            updateForm('name', item.Name);
            updateForm('mandateStartDate', moment(item.MandateStartDate));
            if (item.MandateEndDate)
                updateForm('mandateEndDate', moment(item.MandateEndDate));
            setImageUrl(
                `${config.API_URL}/presidents/images/${item.Id}?date=${currentDate}`,
            );
            setTimeout(() => {
                setImageVisibility(true);
            }, 100);
        }
        if (!modalVisibility) {
            setForm({
                mandateStartDate: null,
                mandateEndDate: null,
            });
            setImageVisibility(false);
        }
        if (modalVisibility && mode === ModalMode.Create) {
            setImageUrl(undefined);
            setTimeout(() => {
                setImageVisibility(true);
            }, 100);
        }
    }, [modalVisibility]);

    return (
        <Modal
            visible={modalVisibility}
            close={close}
            className='edit-ex-president-modal'
            width={700}
        >
            <motion.div
                {...contentSettingsModalMotion}
                className='edit-ex-president-modal__content'
            >
                <p className='edit-ex-president-modal__content__title'>
                    {t(
                        `countyPage.${
                            mode === ModalMode.Edit
                                ? 'EDIT_EX_PRESIDENT'
                                : 'NEW_EX_PRESIDENT'
                        }`,
                    )}
                </p>
                <div className='edit-ex-president-modal__content__body'>
                    <div className='edit-ex-president-modal__content__body__img'>
                        {imageVisibility && (
                            <FileUploader
                                {...register('photo')}
                                initial={imageUrl}
                                className='circular'
                            />
                        )}
                    </div>
                    <p className='edit-ex-president-modal__content__body__label'>
                        {t('form.NAME')}
                    </p>
                    <Input
                        {...register('name', {
                            required: true,
                            resolveError: () =>
                                `${t('form.NAME')} ${t('form.REQUIRED_FIELD')}`,
                        })}
                        className='edit-ex-president-modal__content__body__field'
                    />
                    <p className='edit-ex-president-modal__content__body__label'>
                        {t('countyPage.TERM_START_DATE')}
                    </p>
                    <DatePicker
                        {...register('mandateStartDate')}
                        placeholder={t('countyPage.DATE')}
                        className='edit-ex-president-modal__content__body__field'
                    />
                    <p className='edit-ex-president-modal__content__body__label'>
                        {t('countyPage.TERM_END_DATE')}
                    </p>
                    <DatePicker
                        {...register('mandateEndDate')}
                        placeholder={t('countyPage.END_DATE')}
                        className='edit-ex-president-modal__content__body__field'
                    />
                </div>
                <div className='edit-ex-president-modal__content__footer'>
                    <SiteButton
                        text={t('general.CANCEL')}
                        type='primary'
                        className='edit-ex-president-modal__content__footer__button grey'
                        onClick={close}
                    />
                    <SiteButton
                        text={t('form.SAVE_CHANGES')}
                        type='primary'
                        className='edit-ex-president-modal__content__footer__button'
                        onClick={submit}
                        disabled={
                            mode === ModalMode.Create &&
                            (!form.name ||
                                !form.mandateStartDate ||
                                !form.mandateEndDate ||
                                !form.photo ||
                                (form.photo &&
                                    Array.isArray(form.photo) &&
                                    !(form.photo.length > 0)))
                        }
                    />
                </div>
            </motion.div>
        </Modal>
    );
}

export default EditExPresidentModal;
