import React from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import config from 'config';
import DeleteIcon from 'assets/icons/delete_user_icon.svg';
import EditIcon from 'assets/icons/edit_list_item_icon.svg';
import moment from 'moment';
import { presidentsfilterTableItemContentMotion } from './animation';
import { IExPresidentsFilterTableItemProps } from './interface';

function ExPresidentsFilterTableItem({
    item,
    onDelete,
    onEdit,
}: IExPresidentsFilterTableItemProps) {
    const currentDate = new Date();
    const { t } = useTranslation();
    return (
        <motion.div
            {...presidentsfilterTableItemContentMotion}
            className='ex-presidents-filter-table-item'
        >
            <div className='ex-presidents-filter-table-item__clicable'>
                <div className='ex-presidents-filter-table-item__clicable__left-field'>
                    <div className='ex-presidents-filter-table-item__clicable__left-field__img'>
                        <img
                            src={`${config.API_URL}/presidents/images/${item.Id}?date=${currentDate}`}
                            alt='Item Img'
                        />
                    </div>
                </div>
                <div className='ex-presidents-filter-table-item__clicable__left-field'>
                    <p>{item.Name}</p>
                </div>
                <div className='ex-presidents-filter-table-item__clicable__right-field'>
                    <p>
                        ({moment(item.MandateStartDate).format('YYYY')}{' '}
                        {t('general.TO')}{' '}
                        {moment(item.MandateEndDate).format('YYYY')})
                    </p>
                    <button
                        type='button'
                        className='ex-presidents-filter-table-item__clicable__right-field__edit-button'
                        onClick={onEdit}
                    >
                        <img src={EditIcon} alt='Edit Icon' />
                    </button>
                    <button
                        type='button'
                        className='ex-presidents-filter-table-item__clicable__right-field__delete-button'
                        onClick={onDelete}
                    >
                        <img src={DeleteIcon} alt='Delete Icon' />
                    </button>
                </div>
            </div>
        </motion.div>
    );
}

export default ExPresidentsFilterTableItem;
