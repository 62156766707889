import { IGastronomyListItemDTO } from 'DTOModels/gastronomyDTO';

export enum GastronomyModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface IGastronomyModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: GastronomyModalMode;
    item?: IGastronomyListItemDTO;
    updateList: () => void;
}

export interface IGastronomyForm {
    name?: string;
    description?: string;
    contact?: string;
    photo?: any;
}
