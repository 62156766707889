import React, { useEffect } from 'react';
import { Input, Modal, PhoneInput, useForm } from 'linkwithus';
import './styles.scss';
import { motion } from 'framer-motion';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SiteButton from 'components/SiteButton';
import { emailRegex, phoneRegex } from 'utils/regex';
import { IContactForm, IContactModalProps } from './interface';
import { contentSettingsModalMotion } from './animation';

function ContactModal({
    modalVisibility,
    close,
    item,
    fetch,
}: IContactModalProps) {
    const { t } = useTranslation();

    const { register, updateForm, setForm, registerSubmit } =
        useForm<IContactForm>({
            url: '/contacts',
            onSuccess: () => {
                notification.success({
                    message: t('general.SUCCESS'),
                    description: t('countyPage.INFO_CHANGED_WITH_SUCESS'),
                });
                fetch();
                close();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
                fetch();
                close();
            },
        });

    useEffect(() => {
        if (modalVisibility) {
            updateForm('address', item.address);
            updateForm('email', item.email);
            updateForm('contact', item.contactNumber);
        }
        if (!modalVisibility) {
            setForm({});
        }
    }, [modalVisibility]);

    return (
        <Modal
            visible={modalVisibility}
            close={close}
            className='contact-modal'
            width={500}
        >
            <motion.div
                {...contentSettingsModalMotion}
                className='contact-modal__content'
            >
                <p className='contact-modal__content__title'>
                    {t('general.COUNTY_CONTACTS')}
                </p>
                <div className='contact-modal__content__body'>
                    <p className='contact-modal__content__body__label'>
                        {t('general.ADDRESS')}
                    </p>
                    <Input
                        {...register('address', {
                            required: true,
                            resolveError: () =>
                                `${t('general.ADDRESS')} ${t(
                                    'form.REQUIRED_FIELD',
                                )}`,
                        })}
                        className='contact-modal__content__body__field'
                    />
                    <p className='contact-modal__content__body__label'>
                        {t('form.CONTACT')}
                    </p>
                    <PhoneInput
                        {...register('contact', {
                            required: true,
                            regex: phoneRegex,
                            resolveError: (val, error) => {
                                if (error.type === 'regex') {
                                    return `${t('general.THE')} ${t(
                                        'form.CONTACT',
                                    )} ${t('form.FORMAT_INVALID')}`;
                                }
                                return `${t('form.CONTACT')} ${t(
                                    'form.REQUIRED_FIELD',
                                )}`;
                            },
                        })}
                        className='contact-modal__content__body__field'
                    />
                    <p className='contact-modal__content__body__label'>
                        {t('form.EMAIL')}
                    </p>
                    <Input
                        {...register('email', {
                            required: true,
                            regex: emailRegex,
                            resolveError: (val, error) => {
                                if (error.type === 'regex') {
                                    return `${t('general.THE')} ${t(
                                        'form.EMAIL',
                                    )} ${t('form.FORMAT_INVALID')}`;
                                }
                                return `${t('form.EMAIL')} ${t(
                                    'form.REQUIRED_FIELD',
                                )}`;
                            },
                        })}
                        className='forgot-password-page__form__field'
                    />
                </div>
                <div className='contact-modal__content__footer'>
                    <SiteButton
                        text={t('general.CANCEL')}
                        type='primary'
                        className='contact-modal__content__footer__button grey'
                        onClick={close}
                    />
                    <SiteButton
                        text={t('form.SAVE_CHANGES')}
                        type='primary'
                        className='contact-modal__content__footer__button'
                        {...registerSubmit()}
                    />
                </div>
            </motion.div>
        </Modal>
    );
}

export default ContactModal;
