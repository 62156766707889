import React from 'react';
import HomePage from 'containers/HomePage';
import RequiresAuthentication from 'containers/RequiresAuthentication';
import RequiresAnonymous from 'containers/RequiresAnonymous';
import LoginPage from 'containers/LoginPage';
import PageBackground from 'containers/PageBackground';
import Menu from 'containers/Menu';
import PageContentContainer from 'containers/PageContentContainer';
import OccurrencesPage from 'containers/OccurrencesPage';
import CountyPage from 'containers/CountyPage';
import UsersPage from 'containers/UsersPage';
import SettingsPage from 'containers/SettingsPage';
import SignUpPage from 'containers/SignUpPage';
import ContentSettingsPage from 'containers/ContentSettingsPage';
import ForgotPasswordPage from 'containers/ForgotPasswordPage';

export interface IRoute {
    url: string;
    component: React.ReactElement;
}

export const Routes: IRoute[] = [
    {
        url: '/',
        component: (
            <RequiresAuthentication>
                <Menu tab={1}>
                    <PageContentContainer
                        translationKey='dashboardPage'
                        greetUser
                    >
                        <HomePage />
                    </PageContentContainer>
                </Menu>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/occurrences',
        component: (
            <RequiresAuthentication>
                <Menu tab={2}>
                    <PageContentContainer translationKey='occurrencesPage'>
                        <OccurrencesPage />
                    </PageContentContainer>
                </Menu>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/county',
        component: (
            <RequiresAuthentication>
                <Menu tab={3}>
                    <PageContentContainer translationKey='countyPage'>
                        <CountyPage />
                    </PageContentContainer>
                </Menu>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/users',
        component: (
            <RequiresAuthentication>
                <Menu tab={4}>
                    <PageContentContainer translationKey='usersPage'>
                        <UsersPage />
                    </PageContentContainer>
                </Menu>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/content-settings',
        component: (
            <RequiresAuthentication>
                <Menu tab={5}>
                    <PageContentContainer translationKey='contentSettingsPage'>
                        <ContentSettingsPage />
                    </PageContentContainer>
                </Menu>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/settings',
        component: (
            <RequiresAuthentication>
                <Menu>
                    <PageContentContainer translationKey='settingsPage'>
                        <SettingsPage />
                    </PageContentContainer>
                </Menu>
            </RequiresAuthentication>
        ),
    },
    {
        url: '/login',
        component: (
            <RequiresAnonymous>
                <PageBackground>
                    <LoginPage />
                </PageBackground>
            </RequiresAnonymous>
        ),
    },
    {
        url: '/forgot-password',
        component: (
            <RequiresAnonymous>
                <PageBackground>
                    <ForgotPasswordPage />
                </PageBackground>
            </RequiresAnonymous>
        ),
    },
    {
        url: '/sign-up/:token',
        component: (
            <RequiresAnonymous>
                <PageBackground>
                    <SignUpPage />
                </PageBackground>
            </RequiresAnonymous>
        ),
    },
];
