import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import ClockIcon from 'assets/icons/clock_icon.svg';
import config from 'config';
import EditIcon from 'assets/icons/edit_list_item_icon.svg';
import DeleteUserIcon from 'assets/icons/delete_user_icon.svg';
import { extensiveDate, getMonth } from 'utils/dates';
import { usersfilterTableItemContentMotion } from './animation';
import { dateMonths, IUsersFilterTableItemProps } from './interface';

function UsersFilterTableItem({
    item,
    onClick,
    onEdit,
}: IUsersFilterTableItemProps) {
    const { t } = useTranslation();
    const [creationDate, setCreadtionDate] = useState('');
    const currentDate = new Date();

    useEffect(() => {
        setCreadtionDate(
            extensiveDate(
                item.CreationDate,
                dateMonths[Number(getMonth(item.CreationDate)) - 1],
                true,
            ),
        );
    }, [item]);

    return (
        <motion.div
            {...usersfilterTableItemContentMotion}
            className='users-filter-table-item'
        >
            <div className='users-filter-table-item__clicable'>
                <div className='users-filter-table-item__clicable__left-field'>
                    <div className='users-filter-table-item__clicable__left-field__user'>
                        <div className='users-filter-table-item__clicable__left-field__user__avatar'>
                            <img
                                src={`${config.API_URL}/accounts/images/${item.Id}?date=${currentDate}`}
                                alt='Account Avatar'
                            />
                        </div>
                        <div>
                            <p>{item.Name}</p>
                            <p>{item.Email}</p>
                        </div>
                    </div>
                </div>
                <div className='users-filter-table-item__clicable__left-field'>
                    <p>
                        <img src={ClockIcon} alt='Clock Icon' />
                        {creationDate}
                    </p>
                </div>
                <div className='users-filter-table-item__clicable__right-field'>
                    <p>
                        {item.IsLimited
                            ? t('general.LIMITED_ACCESS')
                            : t('general.FULL_ACCESS')}
                    </p>
                    {/* the first admin account (default one) added, cannot be eliminated */}
                    {item.Id !== 1 && (
                        <>
                            <button
                                type='button'
                                className='users-filter-table-item__clicable__right-field__edit-button'
                                onClick={onEdit}
                            >
                                <img src={EditIcon} alt='Edit Icon' />
                            </button>
                            <button
                                type='button'
                                className='users-filter-table-item__clicable__right-field__delete-button'
                                onClick={onClick}
                            >
                                <img src={DeleteUserIcon} alt='Delete Icon' />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </motion.div>
    );
}

export default UsersFilterTableItem;
