import React from 'react';
import { Modal } from 'linkwithus';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import SuccessIcon from 'assets/icons/arquive_success_icon.svg';
import { IArquivedModalProps } from './interface';

function ArquivedModal({
    modalVisibility,
    close,
    number,
}: IArquivedModalProps) {
    const { t } = useTranslation();
    return (
        <Modal
            visible={modalVisibility}
            close={close}
            className='arquived-modal'
            width={400}
        >
            <div className='arquived-modal__content'>
                <img src={SuccessIcon} alt='Success Img' />
                {number === 1 ? (
                    <p>
                        {t('occurrencesPage.SINGLE_ARQUIVE_MODAL_TEXT')}{' '}
                        {number}{' '}
                        {t('occurrencesPage.SINGLE_ARQUIVE_MODAL_TEXT_2')}
                    </p>
                ) : (
                    <p>
                        {t('occurrencesPage.ARQUIVE_MODAL_TEXT')} {number}{' '}
                        {t('occurrencesPage.ARQUIVE_MODAL_TEXT_2')}
                    </p>
                )}
            </div>
        </Modal>
    );
}

export default ArquivedModal;
