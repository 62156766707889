import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { useAccount } from 'providers/AccountProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { motion } from 'framer-motion';
import { axios } from 'startup';
import config from 'config';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { IOccurrenceStatisticsDTO } from 'DTOModels/statisticsDTO';
import Chart from './components/Chart';
import Summary from './components/Summary';
import { homePageBodyMotion, homePageContentMotion } from './animation';

function HomePage() {
    const { t } = useTranslation();
    const { setAccount } = useAccount();
    const navigate = useNavigate();
    const currentDate = new Date();
    // 0 -> Last Year
    // 1 -> Last Week
    const [fetchByYear, setFetchByYear] = useState(true);
    const [statistics, setStatistics] = useState<IOccurrenceStatisticsDTO>();

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetchStatistics = useCallback(() => {
        axios
            .get(
                `${config.API_URL}/occurrences/statistics?year=${fetchByYear}&currentDate=${currentDate}`,
            )
            .then(response => {
                setStatistics(response.data);
            })
            .catch(() => {
                // force logout
                logout();
            });
    }, [fetchByYear, currentDate]);

    useEffect(() => {
        fetchStatistics();
    }, [fetchByYear]);

    return (
        <motion.div {...homePageContentMotion} className='home-page'>
            <div className='home-page__summary-section'>
                <Summary
                    title={t('dashboardPage.TOTAL_OCCURRENCES')}
                    currentValue={statistics?.Total || 0}
                    pastValue={-1}
                />
                <Summary
                    title={t('dashboardPage.RESOLVED_OCCURRENCES')}
                    currentValue={statistics?.Completed || 0}
                    pastValue={statistics?.PreviousCompleted || 0}
                />
                <Summary
                    title={t('dashboardPage.PENDING_OCCURRENCES')}
                    currentValue={statistics?.Pending || 0}
                    pastValue={statistics?.PreviousPending || 0}
                />
            </div>
            <div className='home-page__chart'>
                <div className='home-page__chart__header'>
                    <p className='home-page__chart__header__title'>
                        {t('occurrencesPage.TITLE')}
                    </p>
                    <button
                        type='button'
                        onClick={() => {
                            setFetchByYear(true);
                        }}
                        className={`${fetchByYear ? 'active' : ''}`}
                    >
                        {t('general.LAST_YEAR')}
                    </button>
                    <button
                        type='button'
                        onClick={() => {
                            setFetchByYear(false);
                        }}
                        className={`${!fetchByYear ? 'active' : ''}`}
                    >
                        {t('general.LAST_WEEK')}
                    </button>
                </div>
                <motion.div
                    {...homePageBodyMotion}
                    className='home-page__chart__chart-info'
                >
                    <Chart
                        data={statistics?.Occurrences || []}
                        groupByYear={fetchByYear}
                    />
                </motion.div>
            </div>
        </motion.div>
    );
}

export default HomePage;
