import { IAccountListItemDTO } from 'DTOModels/accountDTO';

export interface IUsersFilterTableItemProps {
    item: IAccountListItemDTO;
    onClick: () => void;
    onEdit: () => void;
}

export const dateMonths = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];
