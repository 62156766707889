import React from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import config from 'config';
import DeleteIcon from 'assets/icons/delete_user_icon.svg';
import EditIcon from 'assets/icons/edit_list_item_icon.svg';
import { travelAgencyfilterTableItemContentMotion } from './animation';
import { ITravelAgencyFilterTableItemProps } from './interface';

function TravelAgencyFilterTableItem({
    item,
    onDelete,
    onEdit,
}: ITravelAgencyFilterTableItemProps) {
    const currentDate = new Date();
    return (
        <motion.div
            {...travelAgencyfilterTableItemContentMotion}
            className='travel-agency-filter-table-item'
        >
            <div className='travel-agency-filter-table-item__clicable'>
                <div className='travel-agency-filter-table-item__clicable__left-field'>
                    <div className='travel-agency-filter-table-item__clicable__left-field__img'>
                        <img
                            src={`${config.API_URL}/travel/images/${item.Id}?date=${currentDate}`}
                            alt='Item Img'
                        />
                    </div>
                </div>
                <div className='travel-agency-filter-table-item__clicable__left-field'>
                    <p>{item.Travelagencylanguages[0].Name}</p>
                </div>
                <div className='travel-agency-filter-table-item__clicable__right-field'>
                    <p>{item.Travelagencylanguages[0].Location}</p>
                    <button
                        type='button'
                        className='travel-agency-filter-table-item__clicable__right-field__edit-button'
                        onClick={onEdit}
                    >
                        <img src={EditIcon} alt='Edit Icon' />
                    </button>
                    <button
                        type='button'
                        className='travel-agency-filter-table-item__clicable__right-field__delete-button'
                        onClick={onDelete}
                    >
                        <img src={DeleteIcon} alt='Delete Icon' />
                    </button>
                </div>
            </div>
        </motion.div>
    );
}

export default TravelAgencyFilterTableItem;
