import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIcon from 'assets/icons/loading_page_icon.svg';
import Logo from 'assets/logos/municipio_logo.png';
import './styles.scss';

function LoadingPage() {
    const { t } = useTranslation();
    return (
        <div className='loading-page'>
            <div className='loading-page__content'>
                <img
                    src={Logo}
                    alt='logo'
                    className='loading-page__content__logo'
                />
                <div className='loading-page__content__text'>
                    <p className='loading-page__content__text__regular uppercase'>
                        {t('general.COUNTY_OF')}
                    </p>
                    <p className='loading-page__content__text__bold uppercase'>
                        {t('general.COUNTY')}
                    </p>
                    <p className='loading-page__content__text__regular'>
                        {t('general.ISLAND')}
                    </p>
                </div>
                <img
                    className='loading-page__content__loading'
                    src={LoadingIcon}
                    alt='Loading Icon'
                />
            </div>
        </div>
    );
}

export default LoadingPage;
