export enum IOrderFields {
    Id = 'Id',
    NameAndEmail = 'AccountId',
    CreationDate = 'CreationDate',
    LastUpdateDate = 'LastUpdateDate',
    Type = 'OccurrenceTypeId',
    State = 'State',
}

export interface IArquiveOccurrencesForm {
    occurrences?: any;
}

export interface IFilterTableProps {
    historyPage?: boolean;
}
