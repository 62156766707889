import { IEventListItemDTO } from 'DTOModels/eventDTO';

export enum EventModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface IEventModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: EventModalMode;
    item?: IEventListItemDTO;
    updateList: () => void;
}

export interface IEventForm {
    name?: string;
    description?: string;
    photo?: any;
}
