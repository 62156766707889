import { IDropdownItemDTO } from 'DTOModels/dropdownItemDTO';

export function pagination(array: any[], itemsPerPage: number) {
    const res = array.reduce((acc, curr, i) => {
        if (!(i % itemsPerPage)) {
            // if index is 0 or can be divided by the `itemsPerPage`...
            acc.push(array.slice(i, i + itemsPerPage)); // ..push a chunk of the original arrayay to the accumulator
        }
        return acc;
    }, []);
    return res;
}

export function dropdownItems(maxNumberOfElements: number) {
    const numberArray = Array.from(
        { length: maxNumberOfElements },
        (_, i) => i + 1,
    );

    const dropdownArray = new Array<IDropdownItemDTO>();
    numberArray.map((value: number) => {
        const element = {
            id: `${value}`,
            text: `${value}`,
        };
        dropdownArray.push(element);
        return undefined;
    });

    return dropdownArray;
}

export function occurrenceStateDropdown(arrayOfTypes: string[]) {
    const dropdownArray = new Array<IDropdownItemDTO>();
    arrayOfTypes.map((value: string, index: number) => {
        const element = {
            id: `${index}`,
            text: `${value}`,
        };
        dropdownArray.push(element);
        return undefined;
    });
    return dropdownArray;
}
