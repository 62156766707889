import { IOccurrenceListItemDTO } from 'DTOModels/occurrenceDTO';

export enum OccurrenceTypeModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface IOccurrenceTypeModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: OccurrenceTypeModalMode;
    item?: IOccurrenceListItemDTO;
    updateList: () => void;
}

export interface IOccurrenceTypeForm {
    name?: string;
}
