import { IOccurrenceDTO } from 'DTOModels/occurrenceDTO';

export interface IFilterTableItemProps {
    item: IOccurrenceDTO;
    addItem: (itemId: number) => void;
    removeItem: (itemId: number) => void;
    list: number[];
    onClick: () => void;
    selectable?: boolean;
}

export const dateMonths = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];
