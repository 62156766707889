import React from 'react';
import { Modal } from 'linkwithus';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import SiteButton from 'components/SiteButton';
import { IConfirmationModalProps } from './interface';

function ConfirmationModal({
    modalVisibility,
    close,
    confirmation,
    title,
    description,
}: IConfirmationModalProps) {
    const { t } = useTranslation();
    return (
        <Modal
            visible={modalVisibility}
            close={close}
            title={title || t('general.WARNING')}
            className='confirmation-modal'
            width={400}
        >
            <div className='confirmation-modal__content'>
                <p className='confirmation-modal__content__text'>
                    {description}
                </p>
                <div className='confirmation-modal__content__footer'>
                    <SiteButton
                        text={t('general.CANCEL')}
                        type='primary'
                        className='confirmation-modal__content__footer__button grey'
                        onClick={close}
                    />
                    <SiteButton
                        text={t('general.YES')}
                        type='primary'
                        className='confirmation-modal__content__footer__button'
                        onClick={confirmation}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmationModal;
