export enum IOrderType {
    Ascending = 'ASC',
    Descending = 'DESC',
}

export interface IOrderButtonProps {
    name: string;
    type?: IOrderType;
    active: boolean;
    sortable?: boolean;
    activate?: () => void;
    changeOrder?: () => void;
}
