import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import { useForm } from 'linkwithus';
import { forgotPasswordFormMotion } from 'containers/ForgotPasswordPage/animation';
import { IForgotPasswordStepProps } from 'containers/ForgotPasswordPage/interface';
import PinInput from 'components/PinInput';
import { IForgotPasswordStep2FormProps } from './interface';

function Step2({ nextStep, token, setCode, email }: IForgotPasswordStepProps) {
    const { t } = useTranslation();

    const INITIAL_FORM = {
        code: '    ',
        token,
    };

    const { registerSubmit, form, updateForm } =
        useForm<IForgotPasswordStep2FormProps>({
            url: 'accounts/forgot-password/verify-code',
            initialForm: INITIAL_FORM,
            onSuccess: () => {
                setCode(form.code);
                nextStep();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        });

    return (
        <motion.div
            {...forgotPasswordFormMotion}
            className='forgot-password-page__form'
        >
            <p className='forgot-password-page__form__question'>
                {t('forgotPasswordPage.FORGOT_PASSWORD_QUESTION')}
            </p>
            <p className='forgot-password-page__form__instruction-step2'>
                {t('forgotPasswordPage.INSERT_RESET_CODE')}
            </p>
            <p className='forgot-password-page__form__email'>{email}</p>
            <PinInput
                value={form.code}
                onChange={value => {
                    updateForm('code', value);
                }}
            />
            <SiteButton
                text={t('general.SEND')}
                type='primary'
                className='uppercase full-width forgot-password-page__form__step2-button'
                {...registerSubmit()}
            />
        </motion.div>
    );
}

export default Step2;
