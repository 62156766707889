import moment from 'moment';

export function extensiveDate(
    date: Date,
    monthTranslation: string,
    hideHours?: boolean,
) {
    const currentDay = moment(new Date()).format('DD/MM/YYYY');
    const receivedDay = moment(date).format('DD/MM/YYYY');
    const dateDay = moment(date).format('DD');
    const dateYear = moment(date).format('YYYY');
    const dateHours = moment(date).format('HH[h]mm');
    if (currentDay === receivedDay) {
        if (hideHours) {
            return `Hoje`;
        }
        return `Hoje às ${dateHours}`;
    }
    if (hideHours) {
        return `${dateDay} ${monthTranslation} ${dateYear}`;
    }
    return `${dateDay} ${monthTranslation} ${dateYear}, às ${dateHours}`;
}

export function getMonth(date: Date) {
    const month = moment(date).format('M');
    return `${month}`;
}
