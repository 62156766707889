import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { countAndGroupByMonth, countAndGroupByWeek } from 'utils/statistics';
import moment from 'moment';
import { IChartProps } from './interface';

const options = {
    plugins: {
        legend: {
            display: false,
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
    },
};

const currentWeek = [
    Number(moment().subtract(7, 'days').format('D')),
    Number(moment().subtract(6, 'days').format('D')),
    Number(moment().subtract(5, 'days').format('D')),
    Number(moment().subtract(4, 'days').format('D')),
    Number(moment().subtract(3, 'days').format('D')),
    Number(moment().subtract(2, 'days').format('D')),
    Number(moment().subtract(1, 'days').format('D')),
    Number(moment().format('D')),
];

const weekLabels = [
    moment().subtract(7, 'days').format('D/MM'),
    moment().subtract(6, 'days').format('D/MM'),
    moment().subtract(5, 'days').format('D/MM'),
    moment().subtract(4, 'days').format('D/MM'),
    moment().subtract(3, 'days').format('D/MM'),
    moment().subtract(2, 'days').format('D/MM'),
    moment().subtract(1, 'days').format('D/MM'),
    moment().format('D/MM'),
];

function Chart({ data, groupByYear }: IChartProps) {
    const { t } = useTranslation();
    const [graphData, setGraphData] = useState<number[]>([]);
    const [labelsData, setLabelsData] = useState<string[]>([]);

    const dataSet = {
        labels: labelsData,
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        datasets: [
            {
                label: t('occurrencesPage.TITLE'),
                data: graphData,
                fill: false,
                lineTension: 0.3,
                borderColor: '#01ccf2',
                borderWidth: 11,
                pointBorderColor: '#11193C',
                pointBorderWidth: 4,
                pointRadius: 5,
                pointBackgroundColor: '#01ccf2',
            },
        ],
    };

    useEffect(() => {
        if (groupByYear) {
            setGraphData(countAndGroupByMonth(data));
            setLabelsData([
                t('general.JANUARY'),
                t('general.FEBRUARY'),
                t('general.MARCH'),
                t('general.APRIL'),
                t('general.MAY'),
                t('general.JUNE'),
                t('general.JULY'),
                t('general.AUGUST'),
                t('general.SEPTEMBER'),
                t('general.OCTOBER'),
                t('general.NOVEMBER'),
                t('general.DECEMBER'),
            ]);
        } else {
            setGraphData(countAndGroupByWeek(data, currentWeek));
            setLabelsData(weekLabels);
        }
    }, [data, groupByYear]);

    return <Line data={dataSet} options={options} className='graph' />;
}

export default Chart;
