import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { motion } from 'framer-motion';
import SiteButton from 'components/SiteButton';
import { Input, useForm } from 'linkwithus';
import { emailRegex } from 'utils/regex';
import { forgotPasswordFormMotion } from 'containers/ForgotPasswordPage/animation';
import { IForgotPasswordStepProps } from 'containers/ForgotPasswordPage/interface';
import { IForgotPasswordStep1FormProps } from './interface';

function Step1({ nextStep, setToken, setEmail }: IForgotPasswordStepProps) {
    const { t } = useTranslation();

    const { register, registerSubmit, form } =
        useForm<IForgotPasswordStep1FormProps>({
            url: 'accounts/forgot-password/send-email',
            onSuccess: (data: any) => {
                setEmail(form.email);
                setToken(data.Token);
                nextStep();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            },
        });

    return (
        <motion.div
            {...forgotPasswordFormMotion}
            className='forgot-password-page__form'
        >
            <p className='forgot-password-page__form__question'>
                {t('forgotPasswordPage.FORGOT_PASSWORD_QUESTION')}
            </p>
            <p className='forgot-password-page__form__instruction'>
                {t('forgotPasswordPage.FORGOT_PASSWORD_INSTRUCTIONS')}
            </p>
            <p className='forgot-password-page__form__label'>
                {t('form.EMAIL')}
            </p>
            <Input
                {...register('email', {
                    required: true,
                    regex: emailRegex,
                    resolveError: (val, error) => {
                        if (error.type === 'regex') {
                            return `${t('general.THE')} ${t('form.EMAIL')} ${t(
                                'form.FORMAT_INVALID',
                            )}`;
                        }
                        return `${t('form.EMAIL')} ${t('form.REQUIRED_FIELD')}`;
                    },
                })}
                className='forgot-password-page__form__field'
            />
            <SiteButton
                text={t('general.SEND')}
                type='primary'
                className='uppercase full-width forgot-password-page__form__step1-button'
                {...registerSubmit()}
            />
        </motion.div>
    );
}

export default Step1;
