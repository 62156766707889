/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import classNames from 'classnames';
import { IPinInput } from './interface';
import './styles.scss';

function PinInput({ value, onChange }: IPinInput) {
    const { length } = value;
    const refsArray = useRef<(HTMLInputElement | null)[]>(
        Array(length).fill(null),
    );

    function moveToNextInputField(refIndex: number) {
        if (refIndex < refsArray.current.length) {
            setTimeout(() => {
                refsArray.current[refIndex + 1]?.focus();
            }, 0);
        }
    }

    return (
        <div className='pin-input'>
            {refsArray.current.map((ref, i) => (
                <div
                    className={classNames('pin-input__number-box', {
                        filled: value[i] !== ' ',
                        last_box: i === length - 1,
                    })}
                    key={i}
                >
                    <input
                        ref={el => {
                            refsArray.current[i] = el;
                        }}
                        placeholder='0'
                        className='pin-input__number-box__element'
                        value={value[i]}
                        onKeyPress={event => {
                            if (!Number.isNaN(Number(event.key))) {
                                const keyName = event.key;
                                const newCode =
                                    value.substring(0, i) +
                                    keyName +
                                    value.substring(i + 1);
                                onChange(newCode);
                                moveToNextInputField(i);
                            }
                        }}
                    />
                </div>
            ))}
        </div>
    );
}

export default PinInput;
