import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './styles.scss';
import config from 'config';
import { notification } from 'antd';
import { useAccount } from 'providers/AccountProvider';
import SettingsIcon from 'assets/icons/settings_popover_icon.svg';
import LogoutIcon from 'assets/icons/logout_popover_icon.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { userPopoverMotion } from './animation';

function UserPopover() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const [currentDate, setCurrentDate] = useState<Date | undefined>();
    const { account, setAccount } = useAccount();
    const [accountAvatarUrl, setAccountAvatarUrl] = useState<string>();
    const [userPopoverVisible, setUserPopoverVisible] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] =
        useState(false);

    useEffect(() => {
        setUserPopoverVisible(false);
    }, [location.pathname]);

    const closeModal = useCallback(() => {
        setConfirmationModalVisible(false);
    }, []);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
            notification.success({
                message: t('general.SUCCESS'),
                description: t('menu.LOGOUT_SUCCESS'),
            });
        }, 250);
    }, []);

    useEffect(() => {
        const date = new Date();
        if (account) {
            setAccountAvatarUrl(
                `${config.API_URL}/accounts/images/${account?.id}?date=${date}`,
            );
        }
    }, [account, currentDate]);

    return (
        <div className='user-popover'>
            <ConfirmationModal
                modalVisibility={confirmationModalVisible}
                close={closeModal}
                confirmation={logout}
                description={t('menu.CONFIRM_LOGOUT')}
            />
            <button
                type='button'
                className='user-popover__button'
                onClick={() => {
                    setUserPopoverVisible(!userPopoverVisible);
                }}
            >
                <div>
                    <img src={accountAvatarUrl} alt='Account Avatar' />
                </div>
            </button>
            {userPopoverVisible && (
                <motion.div
                    {...userPopoverMotion}
                    className='user-popover__bubble'
                >
                    <div className='user-popover__bubble__content'>
                        <div className='user-popover__bubble__content__top-row'>
                            <img
                                src={accountAvatarUrl}
                                alt='Account Avatar'
                                className='user-popover__bubble__content__top-row__image'
                            />
                            <div className='user-popover__bubble__content__top-row__text'>
                                <p className='user-popover__bubble__content__top-row__text__name'>
                                    {account?.name}
                                </p>
                                <p className='user-popover__bubble__content__top-row__text__email'>
                                    {account?.email}
                                </p>
                            </div>
                        </div>
                        <div className='user-popover__bubble__content__bottom-row'>
                            <button
                                type='button'
                                onClick={() => {
                                    navigate('/settings');
                                    setUserPopoverVisible(false);
                                    const date = new Date();
                                    setCurrentDate(date);
                                }}
                            >
                                <img src={SettingsIcon} alt='Settings Icon' />
                                <p>{t('general.SETTINGS')}</p>
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    setConfirmationModalVisible(true);
                                    setUserPopoverVisible(false);
                                }}
                            >
                                <img src={LogoutIcon} alt='Logout Icon' />
                                <p>{t('general.LOGOUT')}</p>
                            </button>
                        </div>
                        <div className='user-popover__bubble__content__tip' />
                    </div>
                </motion.div>
            )}
        </div>
    );
}

export default UserPopover;
