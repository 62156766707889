import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { notification } from 'antd';
import config from 'config';
import { Input, PasswordInput, PhoneInput, useForm } from 'linkwithus';
import { useAccount } from 'providers/AccountProvider';
import { passwordRegex, phoneRegex } from 'utils/regex';
import SiteButton from 'components/SiteButton';
import FileUploader from 'components/FileUploader';
import { settingsPageContentMotion } from './animation';
import { ISettingsForm } from './interface';

function SettingsPage() {
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const { account, fetchAccount } = useAccount();
    const { register, form, registerSubmit, setForm } = useForm<ISettingsForm>({
        url: '/accounts/backoffice-settings',
        onSuccess: (res: any) => {
            if (res.account) {
                if (fetchAccount) {
                    fetchAccount(() => {
                        // eslint-disable-next-line no-console
                    });
                }
                notification.success({
                    message: t('general.SUCCESS'),
                    description: t('settingsPage.INFO_CHANGED_WITH_SUCESS'),
                });
            }
        },
        onError: (data, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
        resolveData(rawForm) {
            const data = new FormData();
            Object.keys(rawForm).forEach(key => {
                if (key === 'avatar') {
                    rawForm.avatar.forEach((f: any) => {
                        data.append('avatar', f as any);
                    });
                } else
                    data.append(
                        key,
                        rawForm[key as keyof ISettingsForm] as any,
                    );
            });
            return data;
        },
    });

    useEffect(() => {
        if (account) {
            const initialForm: ISettingsForm = {
                name: account.name,
                contact: `${account.contactNumber}`,
            };
            setForm(initialForm);
        }
    }, [account]);

    const { password } = form;

    return (
        <motion.div {...settingsPageContentMotion} className='settings-page'>
            <div className='settings-page__section'>
                <p className='settings-page__section__title'>
                    {t('settingsPage.CHANGE_NAME')}
                </p>
                <div className='settings-page__section__right-column'>
                    <p className='settings-page__section__right-column__label'>
                        {t('form.NAME')}
                    </p>
                    <Input
                        {...register('name', {
                            required: true,
                            resolveError: () =>
                                `${t('form.NAME')} ${t('form.REQUIRED_FIELD')}`,
                        })}
                        className='settings-page__section__right-column__field'
                    />
                </div>
            </div>
            <div className='settings-page__section'>
                <p className='settings-page__section__title'>
                    {t('settingsPage.CHANGE_CONTACT')}
                </p>
                <div className='settings-page__section__right-column'>
                    <p className='settings-page__section__right-column__label'>
                        {t('form.CONTACT')}
                    </p>
                    <PhoneInput
                        {...register('contact', {
                            required: true,
                            regex: phoneRegex,
                            resolveError: (val, error) => {
                                if (error.type === 'regex') {
                                    return `${t('general.THE')} ${t(
                                        'form.CONTACT',
                                    )} ${t('form.FORMAT_INVALID')}`;
                                }
                                return `${t('form.CONTACT')} ${t(
                                    'form.REQUIRED_FIELD',
                                )}`;
                            },
                        })}
                        className='settings-page__section__right-column__field'
                    />
                </div>
            </div>
            <div className='settings-page__section'>
                <p className='settings-page__section__title'>
                    {t('settingsPage.CHANGE_IMAGE')}
                </p>
                <div className='settings-page__section__right-column'>
                    <FileUploader
                        {...register('avatar')}
                        initial={`${config.API_URL}/accounts/images/${account?.id}?date=${currentDate}`}
                        className='circular'
                    />
                </div>
            </div>
            <div className='settings-page__section'>
                <p className='settings-page__section__title'>
                    {t('settingsPage.CHANGE_PASSWORD')}
                </p>
                <div className='settings-page__section__right-column'>
                    <p className='settings-page__section__right-column__label'>
                        {t('form.CURRENT_PASSWORD')}
                    </p>
                    <PasswordInput
                        {...register('oldPassword', {
                            required: true,
                            resolveError: () => {
                                return `${t('form.PASSWORD')} ${t(
                                    'form.REQUIRED_FIELD',
                                )}`;
                            },
                        })}
                        className='settings-page__section__right-column__field'
                    />
                    <p className='settings-page__section__right-column__label'>
                        {t('form.NEW_PASSWORD')}
                    </p>
                    <PasswordInput
                        {...register('password', {
                            required: false,
                            regex: passwordRegex,
                            resolveError: (val, error) => {
                                if (error.type === 'regex') {
                                    return `${t('form.PASSWORD')} ${t(
                                        'form.PASSWORD_FORMAT',
                                    )}`;
                                }
                                return `${t('form.PASSWORD')} ${t(
                                    'form.REQUIRED_FIELD',
                                )}`;
                            },
                        })}
                        className='settings-page__section__right-column__field'
                    />
                    <p className='settings-page__section__right-column__label'>
                        {t('form.CONFIRM_PASSWORD')}
                    </p>
                    <PasswordInput
                        {...register('confirmPassword', {
                            required: !!(form.password && form.password !== ''),
                            isValid: v => v === password,
                            resolveError: (v, err) =>
                                err.type === 'custom-validation'
                                    ? t('form.NON_MATCHING_PASSWORDS')
                                    : `${t('form.PASSWORD')} ${t(
                                          'form.REQUIRED_FIELD',
                                      )}`,
                        })}
                        className='settings-page__section__right-column__field'
                    />
                    <div className='settings-page__section__right-column__button-container'>
                        <SiteButton
                            text={t('form.SAVE_CHANGES')}
                            type='primary'
                            {...registerSubmit()}
                            className='settings-page__section__right-column__button-container__button'
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default SettingsPage;
