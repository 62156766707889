import React, { useEffect } from 'react';
import { Input, Modal, useForm } from 'linkwithus';
import './styles.scss';
import { motion } from 'framer-motion';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SiteButton from 'components/SiteButton';
import {
    OccurrenceTypeModalMode,
    IOccurrenceTypeForm,
    IOccurrenceTypeModalProps,
} from './interface';
import { contentSettingsModalMotion } from './animation';

function OccurrenceTypeModal({
    modalVisibility,
    close,
    item,
    mode = OccurrenceTypeModalMode.Edit,
    updateList,
}: IOccurrenceTypeModalProps) {
    const { t } = useTranslation();

    const { register, form, submit, updateForm, setForm } =
        useForm<IOccurrenceTypeForm>({
            url: `/occurrences/type/${
                mode === OccurrenceTypeModalMode.Edit && item ? item.Id : ''
            }`,
            type: mode === OccurrenceTypeModalMode.Edit ? 'PUT' : 'POST',
            onSuccess: () => {
                notification.success({
                    message: t('general.SUCCESS'),
                    description: t(
                        `contentSettingsPage.${
                            mode === OccurrenceTypeModalMode.Edit
                                ? 'OCCURRENCE_TYPE_EDITED_WITH_SUCCESS'
                                : 'OCCURRENCE_TYPE_CREATED_WITH_SUCCESS'
                        }`,
                    ),
                });
                updateList();
                close();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
                updateList();
                close();
            },
        });

    useEffect(() => {
        if (modalVisibility && mode === OccurrenceTypeModalMode.Edit && !item) {
            close();
        }
        if (modalVisibility && mode === OccurrenceTypeModalMode.Edit && item) {
            updateForm('name', item.Name);
        }
        if (!modalVisibility) {
            setForm({});
        }
    }, [modalVisibility]);

    return (
        <Modal
            visible={modalVisibility}
            close={close}
            className='occurrence-type-modal'
            width={400}
        >
            <motion.div
                {...contentSettingsModalMotion}
                className='occurrence-type-modal__content'
            >
                <p className='occurrence-type-modal__content__title'>
                    {t(
                        `contentSettingsPage.${
                            mode === OccurrenceTypeModalMode.Edit
                                ? 'EDIT_OCCURRENCE_TYPE'
                                : 'CREATE_OCCURRENCE_TYPE'
                        }`,
                    )}
                </p>
                <div className='occurrence-type-modal__content__body'>
                    <p className='occurrence-type-modal__content__body__label'>
                        {t('form.TYPE')}
                    </p>
                    <Input
                        {...register('name', {
                            required: true,
                            resolveError: () =>
                                `${t('form.NAME')} ${t('form.REQUIRED_FIELD')}`,
                        })}
                        className='occurrence-type-modal__content__body__field'
                    />
                </div>
                <div className='occurrence-type-modal__content__footer'>
                    <SiteButton
                        text={t('general.CANCEL')}
                        type='primary'
                        className='occurrence-type-modal__content__footer__button grey'
                        onClick={close}
                    />
                    <SiteButton
                        text={t('form.SAVE_CHANGES')}
                        type='primary'
                        className='occurrence-type-modal__content__footer__button'
                        onClick={submit}
                        disabled={!form.name}
                    />
                </div>
            </motion.div>
        </Modal>
    );
}

export default OccurrenceTypeModal;
