import React, { useEffect, useState } from 'react';
import { Dropdown, Input, Modal, useForm } from 'linkwithus';
import './styles.scss';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SiteButton from 'components/SiteButton';
import { emailRegex } from 'utils/regex';
import {
    IAccountInvite,
    IAccountInviteAccessEnum,
    IInviteForm,
    IInviteUsersModalProps,
} from './interface';

function InviteUsersModal({
    modalVisibility,
    close,
    onSuccess,
}: IInviteUsersModalProps) {
    const { t } = useTranslation();
    const [accountEmail, setAccountEmail] = useState<string | undefined>();
    const [emailError, setEmailError] = useState<string | undefined>();
    const [accountAccess, setAccountAccess] = useState<string | undefined>();
    const [accessError, setAccessError] = useState<string | undefined>();

    const { registerSubmit, updateForm, form } = useForm<IInviteForm>({
        url: '/invites/backoffice',
        onSuccess: () => {
            notification.success({
                message: t('general.SUCCESS'),
                description:
                    form.accounts && form.accounts.length > 1
                        ? t('general.INVITES_SENT_WITH_SUCCESS')
                        : t('general.INVITE_SENT_WITH_SUCCESS'),
            });
            onSuccess();
            setTimeout(() => {
                close();
            }, 100);
        },
        onError: (data, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            if (errorCode === -6) {
                notification.warning({
                    message: t(`general.WARNING`),
                    description: t('usersPage.EMAIL_ALREADY_REGISTERED'),
                });
                onSuccess();
                setTimeout(() => {
                    close();
                }, 100);
            } else {
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
            }
        },
    });

    const verifyInviteSubmission = () => {
        let validSubmission = true;
        if (!accountEmail) {
            setEmailError(t('usersPage.INSERT_EMAIL_TO_INVITE'));
            validSubmission = false;
        }
        if (accountEmail && !emailRegex.test(accountEmail)) {
            setEmailError(t('usersPage.INVALID_EMAIL_INVITE_FORMAT'));
            validSubmission = false;
        }
        if (!accountAccess) {
            setAccessError(t('usersPage.INSERT_TYPE_TO_INVITE'));
            validSubmission = false;
        }
        if (
            accountAccess &&
            accountAccess !== IAccountInviteAccessEnum.Full &&
            accountAccess !== IAccountInviteAccessEnum.Limited
        ) {
            setAccessError(t('usersPage.INVALID_TYPE_TO_INVITE'));
            validSubmission = false;
        }
        if (!validSubmission || !accountEmail || !accountAccess)
            return undefined;
        // check for duplicate
        const checkEmail = (obj: IAccountInvite) => obj.email === accountEmail;
        if (form.accounts.some(checkEmail)) {
            setEmailError(t('usersPage.DUPLICATE_EMAIL'));
            return undefined;
        }
        // no duplicate
        const newAccounts = [...form.accounts];
        newAccounts.push({
            email: accountEmail,
            access: accountAccess as IAccountInviteAccessEnum,
        });
        updateForm('accounts', newAccounts);
        setAccountEmail(undefined);
        setAccountAccess(undefined);
        return undefined;
    };

    const removeInvite = (index: number) => {
        const newArray = [...form.accounts];
        newArray.splice(index, 1);
        updateForm('accounts', newArray);
    };

    useEffect(() => {
        updateForm('accounts', []);
        setAccountEmail(undefined);
        setAccountAccess(undefined);
    }, [modalVisibility]);

    return (
        <Modal
            visible={modalVisibility}
            close={close}
            className='invite-users-modal'
            width={410}
        >
            <div className='invite-users-modal__content'>
                <p className='invite-users-modal__content__text'>
                    {t('usersPage.INVITE_USERS_MODAL_TEXT')}
                </p>
                {/* 
                {renderInput && <MultipleEmailInput update={updateList} />}
                */}
                <div className='invite-users-modal__content__invite-info'>
                    <p>{t('general.ACCESS_TO_THE_PLATAFORM')}</p>
                    <Dropdown
                        items={[
                            {
                                id: IAccountInviteAccessEnum.Limited,
                                text: t('general.LIMITED_ACCESS'),
                            },
                            {
                                id: IAccountInviteAccessEnum.Full,
                                text: t('general.FULL_ACCESS'),
                            },
                        ]}
                        onChange={(value: string) => {
                            setAccountAccess(value);
                            if (value) {
                                setAccessError(undefined);
                            }
                        }}
                        value={accountAccess}
                        placeholder={t('general.ACCESS')}
                        showSearch={false}
                        error={accessError}
                    />
                    <p>{t('form.EMAIL')}</p>
                    <Input
                        value={accountEmail}
                        onChange={(value: string) => {
                            setAccountEmail(value);
                            if (value) {
                                if (emailRegex.test(value)) {
                                    setEmailError(undefined);
                                    return;
                                }
                                setEmailError(
                                    t('usersPage.INVALID_EMAIL_INVITE_FORMAT'),
                                );
                            }
                        }}
                        error={emailError}
                        placeholder={t('form.EMAIL')}
                    />
                    <button type='button' onClick={verifyInviteSubmission}>
                        + {t('usersPage.ADD_INVITE')}
                    </button>
                </div>
                <p className='invite-users-modal__content__invite-list-title'>
                    {t('usersPage.ACCOUNTS_TO_INVITE')}
                </p>
                <div className='invite-users-modal__content__invite-list'>
                    {form.accounts &&
                        form.accounts.length > 0 &&
                        form.accounts.map(
                            (invite: IAccountInvite, index: number) => {
                                return (
                                    <div
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        className={`${
                                            index < form.accounts.length - 1
                                                ? 'border'
                                                : ''
                                        } invite-users-modal__content__invite-list__item`}
                                    >
                                        <div className='invite-users-modal__content__invite-list__item__info'>
                                            <p className='invite-users-modal__content__invite-list__item__info__email'>
                                                {invite.email}
                                            </p>
                                            <p className='invite-users-modal__content__invite-list__item__info__type'>
                                                {t(
                                                    `general.${invite.access.toUpperCase()}_EXTENTION`,
                                                )}
                                            </p>
                                        </div>
                                        <button
                                            type='button'
                                            onClick={() => {
                                                removeInvite(index);
                                            }}
                                            className='invite-users-modal__content__invite-list__item__button'
                                        >
                                            <p>X</p>
                                        </button>
                                    </div>
                                );
                            },
                        )}
                </div>
                <div className='invite-users-modal__content__footer'>
                    <SiteButton
                        text={t('general.CANCEL')}
                        type='primary'
                        className='invite-users-modal__content__footer__button grey'
                        onClick={close}
                    />
                    <SiteButton
                        text={
                            form.accounts && form.accounts.length > 1
                                ? t('usersPage.SEND_INVITES')
                                : t('usersPage.SEND_INVITE')
                        }
                        type='primary'
                        className='invite-users-modal__content__footer__button'
                        {...registerSubmit()}
                        disabled={!form.accounts || form.accounts.length === 0}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default InviteUsersModal;
