import React, { useEffect, useState } from 'react';
import { Modal, TextArea, useForm } from 'linkwithus';
import './styles.scss';
import { motion } from 'framer-motion';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SiteButton from 'components/SiteButton';
import FileUploader from 'components/FileUploader';
import {
    IOccurrenceConclusionForm,
    IOccurrenceConclusionModalProps,
} from './interface';
import { contentSettingsModalMotion } from './animation';

function OccurrenceConclusionModal({
    modalVisibility,
    close,
    item,
    updateList,
}: IOccurrenceConclusionModalProps) {
    const { t } = useTranslation();
    const [imageUrl, setImageUrl] = useState<string>();
    const [imageVisibility, setImageVisibility] = useState(false);

    const { register, form, submit, setForm } =
        useForm<IOccurrenceConclusionForm>({
            url: `/occurrences/conclude/${item ? item.Id : ''}`,
            type: 'POST',
            onSuccess: () => {
                notification.success({
                    message: t('general.SUCCESS'),
                    description: t(
                        `contentSettingsPage.OCCURRENCE_CONCLUDED_WITH_SUCCESS`,
                    ),
                });
                updateList();
                close();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
                updateList();
                close();
            },
            resolveData(rawForm) {
                const data = new FormData();
                Object.keys(rawForm).forEach(key => {
                    if (key === 'photo') {
                        rawForm.photo.forEach((f: any) => {
                            data.append('photo', f as any);
                        });
                    } else
                        data.append(
                            key,
                            rawForm[
                                key as keyof IOccurrenceConclusionForm
                            ] as any,
                        );
                });
                return data;
            },
        });

    useEffect(() => {
        if (modalVisibility && !item) {
            close();
        }
        if (modalVisibility && item) {
            setTimeout(() => {
                setImageVisibility(true);
            }, 100);
        }
        if (!modalVisibility) {
            setForm({});
            setImageVisibility(false);
        }
        if (modalVisibility) {
            setImageUrl(undefined);
            setTimeout(() => {
                setImageVisibility(true);
            }, 100);
        }
    }, [modalVisibility]);

    return (
        <Modal
            visible={modalVisibility}
            close={close}
            className='occurrence-conclusion-modal'
            width={400}
        >
            <motion.div
                {...contentSettingsModalMotion}
                className='occurrence-conclusion-modal__content'
            >
                <p className='occurrence-conclusion-modal__content__title'>
                    {t('contentSettingsPage.CONCLUDE_OCCURRENCE')}
                </p>
                <div className='occurrence-conclusion-modal__content__body'>
                    <p className='occurrence-conclusion-modal__content__body__label'>
                        {t(
                            'contentSettingsPage.OCCURRENCE_CONCLUSION_DESCRIPTION',
                        )}
                    </p>
                    <TextArea
                        {...register('description', {
                            required: true,
                            resolveError: () =>
                                `${t('form.DESCRIPTION')} ${t(
                                    'form.REQUIRED',
                                )}`,
                        })}
                        autoSize
                        className='occurrence-conclusion-modal__content__body__field'
                    />
                    <p className='occurrence-conclusion-modal__content__body__label'>
                        {t('contentSettingsPage.OCCURRENCE_CONCLUSION_PHOTO')}
                    </p>
                    {imageVisibility && (
                        <FileUploader
                            {...register('photo')}
                            initial={imageUrl}
                            className='rectangular'
                        />
                    )}
                </div>
                <div className='occurrence-conclusion-modal__content__footer'>
                    <SiteButton
                        text={t('general.CANCEL')}
                        type='primary'
                        className='occurrence-conclusion-modal__content__footer__button grey'
                        onClick={close}
                    />
                    <SiteButton
                        text={t('form.SAVE_CHANGES')}
                        type='primary'
                        className='occurrence-conclusion-modal__content__footer__button'
                        onClick={submit}
                        disabled={
                            !form.description ||
                            !form.photo ||
                            (form.photo &&
                                Array.isArray(form.photo) &&
                                !(form.photo.length > 0))
                        }
                    />
                </div>
            </motion.div>
        </Modal>
    );
}

export default OccurrenceConclusionModal;
