/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import './styles.scss';
import config from 'config';
import { ReactComponent as ArrowIcon } from 'assets/icons/button_arrow_icon.svg';
import { ICarouselProps } from './interface';
import ImageModal from './components/ImageModal';

function Carousel({ className, elements, admin = false }: ICarouselProps) {
    // carousel currentState
    const [carouselState, setCarouselState] = useState(0);
    // ImageModalVisibility
    const [modalVisibility, setModalVisibility] = useState(false);
    // CurrentImage
    const [currentImageUrl, setCurrentImageUrl] = useState('');

    const openImage = (imageUrl: string) => {
        setCurrentImageUrl(imageUrl);
        setModalVisibility(true);
    };

    const translate = (value: number) => {
        if (value > 0 && Math.abs(carouselState) < elements.length - 1) {
            setCarouselState(carouselState - 1);
        }
        if (value < 0 && carouselState < 0) {
            setCarouselState(carouselState + 1);
        }
    };

    return (
        <div className={`occurrence-carousel ${className || ''}`}>
            <ImageModal
                modalVisibility={modalVisibility}
                close={() => setModalVisibility(false)}
                imageUrl={currentImageUrl}
            />
            {elements.length > 2 && (
                <button
                    className='occurrence-carousel__button left'
                    type='button'
                    onClick={() => {
                        translate(-1);
                    }}
                >
                    <ArrowIcon className='reverse' />
                </button>
            )}
            {elements.length > 2 && (
                <button
                    onClick={() => {
                        translate(1);
                    }}
                    className='occurrence-carousel__button right'
                    type='button'
                >
                    <ArrowIcon />
                </button>
            )}
            <div
                className='occurrence-carousel__translater'
                style={{
                    transform: `translateX(${carouselState * 205}px)`,
                }}
            >
                {elements.map((image, index) => (
                    <button
                        type='button'
                        key={index}
                        onClick={() => {
                            openImage(
                                `${config.API_URL}/occurrences/${
                                    admin ? 'conclusion/image' : 'images'
                                }/${image.Id}?occurrence=${
                                    image.OccurrenceId
                                }&width=700`,
                            );
                        }}
                        className='occurrence-carousel__translater__container'
                    >
                        <div
                            className='occurrence-carousel__translater__container__element'
                            style={{
                                backgroundImage: `url(${
                                    config.API_URL
                                }/occurrences/${
                                    admin ? 'conclusion/image' : 'images'
                                }/${image.Id}?occurrence=${
                                    image.OccurrenceId
                                }&width=700)`,
                            }}
                        />
                    </button>
                ))}
            </div>
        </div>
    );
}

export default Carousel;
