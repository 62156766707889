import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import ClockIcon from 'assets/icons/clock_icon.svg';
import DeleteIcon from 'assets/icons/delete_user_icon.svg';
import { extensiveDate, getMonth } from 'utils/dates';
import { invitesfilterTableItemContentMotion } from './animation';
import { dateMonths, IInvitesFilterTableItemProps } from './interface';

function InvitesFilterTableItem({
    item,
    onClick,
}: IInvitesFilterTableItemProps) {
    const { t } = useTranslation();
    const [dates, setDates] = useState<{
        creationDate: string;
        expirationDate: string;
    }>({ creationDate: '', expirationDate: '' });

    useEffect(() => {
        setDates({
            creationDate: extensiveDate(
                item.CreationDate,
                dateMonths[Number(getMonth(item.CreationDate)) - 1],
                true,
            ),
            expirationDate: extensiveDate(
                item.ExpirationDate,
                dateMonths[Number(getMonth(item.ExpirationDate)) - 1],
                true,
            ),
        });
    }, [item]);

    return (
        <motion.div
            {...invitesfilterTableItemContentMotion}
            className='invites-filter-table-item'
        >
            <div className='invites-filter-table-item__clicable'>
                <div className='invites-filter-table-item__clicable__left-field'>
                    <div className='invites-filter-table-item__clicable__left-field__user'>
                        <p>{item.InvitedEmail}</p>
                    </div>
                </div>
                <div className='invites-filter-table-item__clicable__left-field'>
                    <p>
                        <img src={ClockIcon} alt='Clock Icon' />
                        {dates.creationDate}
                    </p>
                </div>
                <div className='invites-filter-table-item__clicable__left-field'>
                    <p>
                        {new Date(item.ExpirationDate) < new Date()
                            ? t('usersPage.EXPIRED_INVITE')
                            : t('usersPage.ACTIVE_INVITE')}
                    </p>
                </div>
                <div className='invites-filter-table-item__clicable__right-field'>
                    <p>
                        {item.InvitedAccess
                            ? t('general.LIMITED_ACCESS')
                            : t('general.FULL_ACCESS')}
                    </p>
                    <button
                        type='button'
                        className='invites-filter-table-item__clicable__right-field__delete-button'
                        onClick={onClick}
                    >
                        <img src={DeleteIcon} alt='Delete Icon' />
                    </button>
                </div>
            </div>
        </motion.div>
    );
}

export default InvitesFilterTableItem;
