import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { SESSION_COOKIE_KEY, setCookie } from 'services/cookies';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from 'assets/logos/municipio_logo.png';
import './styles.scss';
import SiteButton from 'components/SiteButton';
import { Checkbox, Input, PasswordInput, useForm } from 'linkwithus';
import { emailRegex } from 'utils/regex';
import { useAccount } from 'providers/AccountProvider';
import { ILoginForm } from './interface';
import { loginFormMotion } from './animation';

function LoginPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { fetchAccount } = useAccount();
    const [remember, setRemember] = useState(false);
    const loginSuccess = (token: string) => {
        notification.success({
            message: t('general.SUCCESS'),
            description: t('loginPage.LOGIN_SUCCESS'),
        });
        setCookie(SESSION_COOKIE_KEY, token, remember ? 30 : undefined);
        if (fetchAccount) {
            fetchAccount(() => {
                // eslint-disable-next-line no-console
            });
        }
        setTimeout(() => {
            navigate('/');
        }, 500);
    };

    const { register, registerSubmit } = useForm<ILoginForm>({
        url: '/accounts/login',
        onSuccess: (data: any) => {
            const token = data.Token;
            if (token) loginSuccess(token);
        },
        onError: (data, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
    });

    return (
        <div className='login-page'>
            <motion.div {...loginFormMotion} className='login-page__header'>
                <img src={Logo} alt='County Logo' />
                <p className='login-page__header__medium spacer uppercase'>
                    {t('general.COUNTY_OF')}
                </p>
                <p className='login-page__header__bold uppercase'>
                    {t('general.COUNTY')}
                </p>
                <p className='login-page__header__medium'>
                    {t('general.ISLAND')}
                </p>
            </motion.div>
            <motion.div {...loginFormMotion} className='login-page__form'>
                <p className='login-page__form__label'>{t('form.EMAIL')}</p>
                <Input
                    {...register('email', {
                        required: true,
                        regex: emailRegex,
                        resolveError: (val, error) => {
                            if (error.type === 'regex') {
                                return `${t('general.THE')} ${t(
                                    'form.EMAIL',
                                )} ${t('form.FORMAT_INVALID')}`;
                            }
                            return `${t('form.EMAIL')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='login-page__form__field'
                />
                <p className='login-page__form__label'>{t('form.PASSWORD')}</p>
                <PasswordInput
                    {...register('password', {
                        required: true,
                        resolveError: () => {
                            return `${t('form.PASSWORD')} ${t(
                                'form.REQUIRED_FIELD',
                            )}`;
                        },
                    })}
                    className='login-page__form__field half-margin'
                />
                <div className='login-page__form__remember-section'>
                    <Checkbox
                        value={remember}
                        onChange={() => setRemember(!remember)}
                    >
                        <p>{t('general.REMEMBER')}</p>
                    </Checkbox>
                    <Link
                        to='/forgot-password'
                        className='login-page__form__remember-section__link'
                    >
                        <span>{t('general.FORGOT_PASSWORD')}</span>
                    </Link>
                </div>
                <SiteButton
                    text={t('general.LOGIN')}
                    type='primary'
                    className='uppercase full-width login-page__button'
                    {...registerSubmit()}
                />
            </motion.div>
        </div>
    );
}

export default LoginPage;
