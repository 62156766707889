/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { Upload, notification } from 'antd';
import classNames from 'classnames';
import EditAvatarIcon from 'assets/icons/edit_avatar_icon.svg';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { InputErrorWrapper } from 'linkwithus';
import { filesType, IFileUploaderFile, IFileUploaderProps } from './interface';
import './styles.scss';

const { Dragger } = Upload;

// 3 MB
const MAX_FILE_SIZE = 3;
// 5 files
const MAX_NUMBER_OF_FILES = 5;

function validNumberOfDocuments(x: number) {
    return x < MAX_NUMBER_OF_FILES;
}

function validDocumentSize(x: number) {
    return x <= MAX_FILE_SIZE * 1000000;
}

function niceBytes(x: number) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = x;
    // eslint-disable-next-line no-plusplus
    while (n >= 1024 && ++l) {
        n /= 1024;
    }
    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

function FileUploader({
    multiple = true,
    accept,
    disabled,
    onChange,
    className,
    error,
    value,
    disableFileRemove,
    initial,
    ...rest
}: IFileUploaderProps) {
    const fileListRef = useRef<UploadFile[]>([]);
    const [imageUploadedUrl, setImageUploadedUrl] = useState<string>();
    const { t } = useTranslation();

    useEffect(() => {
        if (initial) {
            setImageUploadedUrl(initial);
        }
    }, [initial]);

    const onFileChanges = ({ fileList }: any) => {
        if (multiple) {
            let newFiles: (RcFile | IFileUploaderFile)[] = [];

            if (Array.isArray(value)) newFiles = value;
            else if (value) newFiles = [value];

            fileList.forEach((f: any) => {
                if (
                    !newFiles.some((e: any) => e.uid === f.originFileObj!.uid)
                ) {
                    let errorType = '';
                    const type = f.originFileObj!.type as string;
                    const regex = /image.(jpg|png|jpeg)$/;
                    // check size, type, number of documents
                    const validSize = validDocumentSize(f.originFileObj!.size);
                    const validType = regex.test(type);
                    const validNumber = validNumberOfDocuments(
                        fileListRef.current.length,
                    );
                    if (!validSize) errorType = 'IMAGE_SIZE_INVALID';
                    if (!validType) errorType = 'IMAGE_FORMAT_INVALID';
                    if (!validNumber) errorType = 'MAX_IMAGE_NUMBER_REACHED';
                    if (!validType || !validSize || !validNumber) {
                        notification.error({
                            message: t('general.ERROR'),
                            description: t(`occurrences.${errorType}`),
                        });
                    }

                    if (validType && validSize && validNumber) {
                        fileListRef.current.push(f);
                        newFiles.push(f.originFileObj!);
                        const src = URL.createObjectURL(f.originFileObj!);
                        setImageUploadedUrl(src);
                    }
                }
            });

            onChange(newFiles as any);
        } else onChange(fileList.length ? fileList[0].originFileObj! : null);
    };

    const removeFile = (index: number) => {
        let newFiles: (RcFile | IFileUploaderFile)[] = [];

        if (Array.isArray(value)) newFiles = value;
        else if (value) newFiles = [value];

        let currentUid: any;
        if (newFiles[index]) {
            const { uid } = newFiles[index] as any;
            currentUid = uid;
        }

        if (currentUid) {
            const indexToRemove = fileListRef.current.findIndex(
                e => e.originFileObj!.uid === currentUid,
            );
            fileListRef.current.splice(indexToRemove, 1);
        }

        newFiles.splice(index, 1);

        if (multiple) onChange(newFiles as any);
        else onChange(newFiles.length ? (newFiles[0] as any) : null);
    };

    let resolvedFiles: filesType = [];

    if (Array.isArray(value)) resolvedFiles = value;
    else if (value) resolvedFiles = [value];

    return (
        <InputErrorWrapper
            error={error}
            className={classNames('file-uploader-error', className, {
                error,
            })}
        >
            <div className='file-uploader'>
                {!imageUploadedUrl && (
                    <Dragger
                        disabled={disabled}
                        multiple={multiple}
                        accept={accept}
                        customRequest={({ onSuccess }) => onSuccess!('ok')}
                        onChange={onFileChanges}
                        showUploadList={false}
                        fileList={fileListRef.current}
                        className='file-uploader__dragger'
                        {...rest}
                    >
                        <div className='file-uploader__dragger__header'>
                            {t('settingsPage.UPLOADER_TEXT')}
                        </div>
                    </Dragger>
                )}
                {imageUploadedUrl && (
                    <img
                        src={imageUploadedUrl}
                        alt='Img Uploaded'
                        className='file-uploader__avatar'
                    />
                )}
                {resolvedFiles.length !== 0 && (
                    <div className='file-uploader__separator' />
                )}
                {imageUploadedUrl && (
                    <div className='file-uploader__content'>
                        <div className='file-uploader__content__list'>
                            <div className='file-uploader__content__list__item'>
                                <div
                                    className='file-uploader__content__list__item__remove'
                                    onClick={() => {
                                        setImageUploadedUrl(undefined);
                                        if (Array.isArray(resolvedFiles)) {
                                            if (resolvedFiles.length >= 0) {
                                                removeFile(0);
                                            }
                                        }
                                    }}
                                >
                                    <img src={EditAvatarIcon} alt='Edit Icon' />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </InputErrorWrapper>
    );
}

export default FileUploader;
