import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'linkwithus';
import ClockIcon from 'assets/icons/clock_icon.svg';
import { extensiveDate, getMonth } from 'utils/dates';
import { filterTableItemContentMotion } from './animation';
import { dateMonths, IFilterTableItemProps } from './interface';

function FilterTableItem({
    item,
    addItem,
    removeItem,
    list,
    onClick,
    selectable = true,
}: IFilterTableItemProps) {
    const { t } = useTranslation();
    const [creationDate, setCreadtionDate] = useState('');
    const [lastUpdateDate, setLastUpdateDate] = useState('');

    useEffect(() => {
        setCreadtionDate(
            extensiveDate(
                item.CreationDate,
                dateMonths[Number(getMonth(item.CreationDate)) - 1],
            ),
        );
    }, [item]);

    useEffect(() => {
        if (item.LastUpdateDate)
            setLastUpdateDate(
                extensiveDate(
                    item.LastUpdateDate,
                    dateMonths[Number(getMonth(item.LastUpdateDate)) - 1],
                ),
            );
    }, [item]);

    return (
        <motion.div
            {...filterTableItemContentMotion}
            className='filter-table-item'
        >
            {selectable && (
                <div className='filter-table-item__selector'>
                    <Checkbox
                        value={list.indexOf(item.Id) > -1}
                        onChange={(value: boolean) => {
                            if (value) {
                                addItem(item.Id);
                                return;
                            }
                            removeItem(item.Id);
                        }}
                    >
                        {' '}
                    </Checkbox>
                </div>
            )}
            <button
                type='button'
                className='filter-table-item__clicable'
                onClick={onClick}
            >
                <div className='filter-table-item__clicable__field'>
                    <p>{item.Id}</p>
                </div>
                <div className='filter-table-item__clicable__field'>
                    <div className='filter-table-item__clicable__field__user'>
                        <p>{item.Account.Name}</p>
                        <p>{item.Account.Email}</p>
                    </div>
                </div>
                <div className='filter-table-item__clicable__field'>
                    <p>
                        <img src={ClockIcon} alt='Clock Icon' />
                        {creationDate}
                    </p>
                </div>
                <div className='filter-table-item__clicable__field'>
                    <p>{item.OccurrenceType.Name}</p>
                </div>
                <div className='filter-table-item__clicable__field'>
                    <p>
                        {item.LastUpdateDate ? (
                            <img src={ClockIcon} alt='Clock Icon' />
                        ) : (
                            ''
                        )}
                        {item.LastUpdateDate ? lastUpdateDate : ''}
                    </p>
                </div>
                <div className='filter-table-item__clicable__field'>
                    <p
                        className={`filter-table-item__clicable__field__state ${
                            item.State === 0 ? 'pending' : 'concluded'
                        }`}
                    >
                        {item.State === 0
                            ? t('general.PENDING')
                            : t('general.CONCLUDED')}
                    </p>
                </div>
            </button>
        </motion.div>
    );
}

export default FilterTableItem;
