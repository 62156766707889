import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import SiteButton from 'components/SiteButton';
import { axios } from 'startup';
import config from 'config';
import { Spin, notification } from 'antd';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import { useAccount } from 'providers/AccountProvider';
import { useNavigate } from 'react-router-dom';
import { IContactDTO } from 'DTOModels/contactDTO';
import { formatContact } from 'utils/format';
import ContactModal from './components/ContactModal';

function ContactSection() {
    const { t } = useTranslation();
    const { setAccount } = useAccount();
    const navigate = useNavigate();
    const [info, setInfo] = useState<IContactDTO | undefined>();
    const [modalVisibility, setModalVisibility] = useState(false);

    const openModal = () => {
        setModalVisibility(true);
    };

    const closeModal = () => {
        setModalVisibility(false);
    };

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const fetch = () => {
        const currentDate = new Date();
        axios
            .get(`${config.API_URL}/contacts?date=${currentDate}`)
            .then(response => {
                setInfo(response.data.results);
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div className='content-settings-contact-section'>
            {!info ? (
                <div className='content-settings-contact-section__empty'>
                    <Spin />
                </div>
            ) : (
                <div className='content-settings-contact-section__content'>
                    <ContactModal
                        modalVisibility={modalVisibility}
                        close={closeModal}
                        fetch={fetch}
                        item={info}
                    />
                    <div className='content-settings-contact-section__content__header'>
                        <p>{t('general.COUNTY_CONTACTS')}</p>
                        <SiteButton
                            text={t('general.EDIT_CONTACTS')}
                            type='primary'
                            onClick={openModal}
                        />
                    </div>
                    <p>
                        <span>{t('general.ADDRESS')}</span>: {info.address}
                    </p>
                    <p>
                        <span>{t('general.PHONE')}</span>:{' '}
                        {formatContact(info.contactNumber)}
                    </p>
                    <p>
                        <span>{t('form.EMAIL')}</span>: {info.email}
                    </p>
                </div>
            )}
        </div>
    );
}

export default ContactSection;
