/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'providers/AccountProvider';
import ArrowIcon from 'assets/icons/button_arrow_icon.svg';
import ArquiveIcon from 'assets/icons/arquive_icon.svg';
import './styles.scss';
import { axios } from 'startup';
import config from 'config';
import { Spin, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Dropdown, SearchInput, useForm } from 'linkwithus';
import { IDropdownItemDTO } from 'DTOModels/dropdownItemDTO';
import { IOccurrenceDTO } from 'DTOModels/occurrenceDTO';
import { removeCookie, SESSION_COOKIE_KEY } from 'services/cookies';
import {
    dropdownItems,
    occurrenceStateDropdown,
    pagination,
} from 'utils/pagination';
import { ArquivedEnum } from 'utils/dataTypes';
import ConfirmationModal from 'components/ConfirmationModal';
import OrderButton from './components/OrderButton';
import { IOrderType } from './components/OrderButton/interface';
import {
    IArquiveOccurrencesForm,
    IFilterTableProps,
    IOrderFields,
} from './interface';
import FilterTableItem from './components/FilterTableItem';
import ArquivedModal from './components/ArquivedModal';
import OccurrenceSideBar from './components/OccurrenceSideBar';

function FilterTable({ historyPage = false }: IFilterTableProps) {
    const { t } = useTranslation();
    const [currentOccurrenceToOpen, setCurrentOccurrenceToOpen] =
        useState<IOccurrenceDTO>();
    const [
        currentOccurrenceToOpenVisibility,
        setCurrentOccurrenceToOpenVisibility,
    ] = useState(false);
    const [arquivedWithSuccess, setArquivedWithSuccess] = useState(false);
    const [confirmationModalVisibility, setConfirmationModalVisibility] =
        useState(false);
    const [numberOfArquivedWithSuccess, setNumberOfArquivedWithSuccess] =
        useState(0);
    const [loading, setLoading] = useState(false);
    const [selectAllFromCurrentPage, setSelectAllFromCurrentPage] =
        useState(false);
    const navigate = useNavigate();
    const { setAccount } = useAccount();
    const [totalItems, setTotalItems] = useState<IOccurrenceDTO[]>([]);
    const [itemsPagination, setItemsPagination] = useState<IOccurrenceDTO[][]>(
        [],
    );
    const [itemsPaginationDropdown, setItemsPaginationDropdown] = useState<
        IDropdownItemDTO[]
    >([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalNumberOfOccurrences, setTotalNumberOfOccurrences] = useState(0);
    const [totalPageNumber, setTotalPageNumber] = useState(5);
    const [typeFilter, setTypeFilter] = useState<string | undefined>();
    const [stateFilter, setStateFilter] = useState<string | undefined>();
    const [intermediarySearchFilter, setIntermediarySearchFilter] = useState<
        string | undefined
    >();
    const [searchFilter, setSearchFilter] = useState<string | undefined>();
    const [occurrenceTypes, setOccurrenceTypes] = useState<IDropdownItemDTO[]>(
        [],
    );
    const [occurrenceStates, setOccurrenceStates] = useState<
        IDropdownItemDTO[]
    >([]);
    const [currentOrderType, setCurrentOrderType] = useState(
        IOrderFields.CreationDate,
    );
    const [currentOrder, setCurrentOrder] = useState(IOrderType.Descending);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    useEffect(() => {
        const currentDate = new Date();
        axios
            .get(`/occurrences/types?date=${currentDate}`)
            .then((response: any) => {
                setOccurrenceTypes(response.data.Types);
            });
    }, []);

    const changeOrderType = useCallback((order: IOrderFields) => {
        setCurrentOrderType(order);
        setCurrentOrder(IOrderType.Descending);
    }, []);

    const logout = useCallback(() => {
        // timeout just to add a bit of delay
        setTimeout(() => {
            removeCookie(SESSION_COOKIE_KEY);
            if (setAccount) setAccount(undefined);
            navigate('/login');
        }, 250);
        notification.error({
            message: t('general.ERROR'),
            description: t('general.SUSPICIOUS_ACTIVITY'),
        });
    }, []);

    const changeOrder = useCallback(() => {
        if (currentOrder === IOrderType.Ascending)
            setCurrentOrder(IOrderType.Descending);
        else {
            setCurrentOrder(IOrderType.Ascending);
        }
    }, [currentOrder]);

    const clearAllFilters = useCallback(() => {
        setTypeFilter(undefined);
        setStateFilter(undefined);
        setIntermediarySearchFilter(undefined);
        setSearchFilter(undefined);
    }, []);

    const addItemToSelected = (itemId: number) => {
        const index = selectedItems.indexOf(itemId);
        if (index === -1) {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const removeItemFromSelected = (itemId: number) => {
        const array = [...selectedItems];
        const index = array.indexOf(itemId);
        if (index > -1) {
            array.splice(index);
        }
        setSelectedItems(array);
    };

    const fetchList = () => {
        setLoading(true);
        // currentOrderType, currentOrder
        const currentDate = new Date();
        const orderTypeString = currentOrder
            ? `&orderType=${currentOrder}`
            : '';
        const orderFieldString = currentOrderType
            ? `&orderField=${currentOrderType}`
            : '';
        const typeString = typeFilter ? `&type=${typeFilter}` : '';
        const stateString = stateFilter ? `&state=${stateFilter}` : '';
        const searchString =
            searchFilter && searchFilter !== ''
                ? `&search=${searchFilter}`
                : '';
        axios
            .get(
                `${
                    config.API_URL
                }/occurrences?limit=${1}${typeString}${stateString}${searchString}${orderTypeString}${orderFieldString}&archived=${
                    historyPage
                        ? ArquivedEnum.Arquived
                        : ArquivedEnum.NotArquived
                }&date=${currentDate}`,
            )
            .then(response => {
                setTotalItems(
                    response.data.Occurrences.map((item: IOccurrenceDTO) => ({
                        ...item,
                    })),
                );
                setTotalNumberOfOccurrences(response.data.Total);
                setCurrentPageNumber(1);
                setSelectAllFromCurrentPage(false);
                setSelectedItems([]);
            })
            .catch(() => {
                // force logout
                logout();
            });
    };

    const { updateForm, submit } = useForm<IArquiveOccurrencesForm>({
        url: '/occurrences/arquive',
        onSuccess: (data: any) => {
            setArquivedWithSuccess(true);
            setNumberOfArquivedWithSuccess(Number(data.Number));
            fetchList();
        },
        onError: (data: any, error: any) => {
            const errorCode = Number(error.errorCode) || 0;
            notification.error({
                message: t(`general.ERROR`),
                description: t(`errors.${errorCode}`),
            });
        },
    });

    useEffect(() => {
        fetchList();
    }, [
        typeFilter,
        stateFilter,
        searchFilter,
        currentOrder,
        currentOrderType,
        historyPage,
    ]);

    useEffect(() => {
        setItemsPagination(pagination(totalItems, totalPageNumber));
        setLoading(false);
    }, [totalItems, totalPageNumber]);

    useEffect(() => {
        setItemsPaginationDropdown(
            dropdownItems(
                totalNumberOfOccurrences && totalNumberOfOccurrences > 5
                    ? totalNumberOfOccurrences
                    : 5,
            ),
        );
    }, [totalNumberOfOccurrences]);

    useEffect(() => {
        setOccurrenceStates(
            occurrenceStateDropdown([
                t('general.PENDING'),
                t('general.CONCLUDED'),
            ]),
        );
    }, []);

    // throttle set of input Search (300 miliseconds)
    useEffect(() => {
        const interval = setInterval(() => {
            setSearchFilter(intermediarySearchFilter);
        }, 300);
        return () => {
            clearInterval(interval);
        };
    }, [intermediarySearchFilter]);

    useEffect(() => {
        if (
            selectAllFromCurrentPage &&
            itemsPagination &&
            itemsPagination[currentPageNumber - 1]
        ) {
            const newArray = [...selectedItems];
            itemsPagination[currentPageNumber - 1].forEach(
                (item: IOccurrenceDTO) => {
                    const index = newArray.indexOf(item.Id);
                    if (index === -1) {
                        newArray.push(item.Id);
                    }
                },
            );
            setSelectedItems(newArray);
            return;
        }
        setSelectedItems([]);
    }, [selectAllFromCurrentPage]);

    useEffect(() => {
        if (selectedItems) {
            updateForm('occurrences', selectedItems);
        }
    }, [selectedItems]);

    return (
        <div className='filter-table'>
            <ArquivedModal
                modalVisibility={arquivedWithSuccess}
                close={() => {
                    setArquivedWithSuccess(false);
                }}
                number={numberOfArquivedWithSuccess}
            />
            <ConfirmationModal
                modalVisibility={confirmationModalVisibility}
                close={() => {
                    setConfirmationModalVisibility(false);
                }}
                confirmation={() => {
                    setConfirmationModalVisibility(false);
                    submit();
                }}
                description={
                    selectedItems.length > 1
                        ? t(
                              'occurrencesPage.MULTIPLE_ARQUIVE_CONFIRMATION_TEXT',
                          )
                        : t('occurrencesPage.ARQUIVE_CONFIRMATION_TEXT')
                }
            />
            <OccurrenceSideBar
                modalVisibility={currentOccurrenceToOpenVisibility}
                close={() => {
                    setCurrentOccurrenceToOpenVisibility(false);
                }}
                fetch={() => {
                    fetchList();
                }}
                occurrence={currentOccurrenceToOpen}
                showImages={!historyPage}
            />
            <div className='filter-table__header'>
                <div className='filter-table__header__left-column'>
                    <SearchInput
                        onChange={(e: string) => {
                            setIntermediarySearchFilter(e);
                        }}
                        value={intermediarySearchFilter}
                        placeholder={t('general.SEARCH')}
                        className='filter-table__header__left-column__search'
                    />
                    <Dropdown
                        items={occurrenceTypes}
                        value={typeFilter}
                        onChange={(e: string) => setTypeFilter(e)}
                        allowClear={false}
                        showSearch={false}
                        placeholder={t('general.TYPE')}
                        className='filter-table__header__left-column__dropdown'
                    />
                    <Dropdown
                        items={occurrenceStates}
                        value={stateFilter}
                        onChange={(e: string) => setStateFilter(e)}
                        allowClear={false}
                        showSearch={false}
                        placeholder={t('general.STATE')}
                        className='filter-table__header__left-column__dropdown'
                    />
                    <button
                        type='button'
                        className='filter-table__header__left-column__button'
                        onClick={() => clearAllFilters()}
                    >
                        {t('general.CLEAR_FILTERS')}
                    </button>
                </div>
                {!historyPage && (
                    <div className='filter-table__header__right-column'>
                        <button
                            type='button'
                            onClick={() => {
                                if (selectedItems.length === 0) {
                                    notification.error({
                                        message: t('general.ERROR'),
                                        description: t(
                                            'general.NO_OCCURRENCES_TO_ARQUIVE',
                                        ),
                                    });
                                    return;
                                }
                                setConfirmationModalVisibility(true);
                            }}
                        >
                            <img src={ArquiveIcon} alt='Arquive Icon' />
                            {t('general.ARQUIVE')}
                        </button>
                    </div>
                )}
            </div>
            <div className='filter-table__order'>
                {!historyPage && (
                    <div className='filter-table__order__selector'>
                        <Checkbox
                            value={selectAllFromCurrentPage}
                            onChange={() => {
                                setSelectAllFromCurrentPage(
                                    !selectAllFromCurrentPage,
                                );
                            }}
                        >
                            {' '}
                        </Checkbox>
                    </div>
                )}
                <div
                    className={`filter-table__order__field ${
                        historyPage ? 'no-select' : ''
                    }`}
                >
                    <OrderButton
                        name={t('general.ID')}
                        type={currentOrder}
                        active={currentOrderType === IOrderFields.Id}
                        activate={() => {
                            changeOrderType(IOrderFields.Id);
                        }}
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
                <div
                    className={`filter-table__order__field ${
                        historyPage ? 'no-select' : ''
                    }`}
                >
                    <OrderButton
                        name={t('general.NAME_AND_EMAIL')}
                        type={currentOrder}
                        active={currentOrderType === IOrderFields.NameAndEmail}
                        activate={() =>
                            changeOrderType(IOrderFields.NameAndEmail)
                        }
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
                <div
                    className={`filter-table__order__field ${
                        historyPage ? 'no-select' : ''
                    }`}
                >
                    <OrderButton
                        name={t('general.OCCURRENCE_DATE')}
                        type={currentOrder}
                        active={currentOrderType === IOrderFields.CreationDate}
                        activate={() =>
                            changeOrderType(IOrderFields.CreationDate)
                        }
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
                <div
                    className={`filter-table__order__field ${
                        historyPage ? 'no-select' : ''
                    }`}
                >
                    <OrderButton
                        name={t('general.TYPE')}
                        active={currentOrderType === IOrderFields.Type}
                    />
                </div>
                <div
                    className={`filter-table__order__field ${
                        historyPage ? 'no-select' : ''
                    }`}
                >
                    <OrderButton
                        name={t('general.LAST_UPDATE')}
                        type={currentOrder}
                        active={
                            currentOrderType === IOrderFields.LastUpdateDate
                        }
                        activate={() =>
                            changeOrderType(IOrderFields.LastUpdateDate)
                        }
                        changeOrder={() => changeOrder()}
                        sortable
                    />
                </div>
                <div
                    className={`filter-table__order__field last ${
                        historyPage ? 'no-select' : ''
                    }`}
                >
                    <OrderButton
                        name={t('general.STATE')}
                        active={currentOrderType === IOrderFields.State}
                    />
                </div>
            </div>
            {!loading && itemsPagination && itemsPagination.length > 0 && (
                <div className='filter-table__items-list'>
                    {itemsPagination[currentPageNumber - 1].map(
                        (occurrence: IOccurrenceDTO, index: number) => {
                            return (
                                <FilterTableItem
                                    item={occurrence}
                                    key={index}
                                    addItem={addItemToSelected}
                                    removeItem={removeItemFromSelected}
                                    list={selectedItems}
                                    onClick={() => {
                                        setCurrentOccurrenceToOpen(occurrence);
                                        setCurrentOccurrenceToOpenVisibility(
                                            true,
                                        );
                                    }}
                                    selectable={!historyPage}
                                />
                            );
                        },
                    )}
                </div>
            )}
            {loading && (
                <div className='filter-table__loading'>
                    <Spin />
                </div>
            )}
            <div className='filter-table__footer'>
                <div className='filter-table__footer__left-column'>
                    <p>{t('general.SHOW')}</p>
                    <Dropdown
                        items={itemsPaginationDropdown}
                        value={`${totalPageNumber}`}
                        onChange={(e: string) => {
                            setCurrentPageNumber(1);
                            setTotalPageNumber(Number(e));
                        }}
                        allowClear={false}
                        showSearch={false}
                        placeholder={t('form.TYPE_PLACEHOLDER')}
                        className='filter-table__footer__left-column__dropdown'
                    />
                    <p>
                        {t('general.OF')} {totalNumberOfOccurrences}{' '}
                        {t('general.RESULTS')}
                    </p>
                </div>
                <div className='filter-table__footer__right-column'>
                    <p>
                        {t('general.PAGE')} {currentPageNumber}{' '}
                        {t('general.OF')} {itemsPagination.length || 1}
                    </p>
                    <button
                        type='button'
                        className='filter-table__footer__right-column__center-button'
                        onClick={() => {
                            if (currentPageNumber === 1) return;
                            setCurrentPageNumber(currentPageNumber - 1);
                        }}
                    >
                        <img src={ArrowIcon} alt='Arrow Icon' />
                    </button>
                    <button
                        type='button'
                        onClick={() => {
                            if (
                                currentPageNumber + 1 ===
                                itemsPagination.length + 1
                            )
                                return;
                            setCurrentPageNumber(currentPageNumber + 1);
                        }}
                    >
                        <img src={ArrowIcon} alt='Arrow Icon' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FilterTable;
