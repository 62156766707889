import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'providers/AccountProvider';
import { countyPageContentMotion } from './animation';
import CurrentPresident from './components/CurrentPresident';
import CurrentAssemblyPresident from './components/CurrentAssemblyPresident';
import CountyEntityFilterTable from './components/CountyEntityFilterTable';
import ExPresidentsFilterTable from './components/ExPresidentsFilterTable';

function CountyPage() {
    const { account } = useAccount();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        if (account && account.isLimited) {
            navigate('/');
        }
    }, [account]);

    return (
        <motion.div {...countyPageContentMotion} className='county-page'>
            <div className='county-page__header'>
                <button
                    type='button'
                    className={`county-page__header__tab ${
                        activeTab === 1 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(1)}
                >
                    {t('countyPage.COUNTY_PRESIDENT')}
                </button>
                <button
                    type='button'
                    className={`county-page__header__tab ${
                        activeTab === 2 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(2)}
                >
                    {t('countyPage.ASSEMBLY_PRESIDENT')}
                </button>
                <button
                    type='button'
                    className={`county-page__header__tab ${
                        activeTab === 3 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(3)}
                >
                    {t('countyPage.ELECTED')}
                </button>
                <button
                    type='button'
                    className={`county-page__header__tab ${
                        activeTab === 4 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(4)}
                >
                    {t('countyPage.ALDERMEN')}
                </button>
                <button
                    type='button'
                    className={`county-page__header__tab ${
                        activeTab === 5 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(5)}
                >
                    {t('countyPage.EX_PRESIDENTS')}
                </button>
                <div className='county-page__header__filler' />
            </div>
            <div className='county-page__container'>
                {activeTab === 1 && (
                    <motion.div {...countyPageContentMotion}>
                        <CurrentPresident />
                    </motion.div>
                )}
                {activeTab === 2 && (
                    <motion.div {...countyPageContentMotion}>
                        <CurrentAssemblyPresident />
                    </motion.div>
                )}
                {activeTab === 3 && (
                    <motion.div {...countyPageContentMotion}>
                        <CountyEntityFilterTable url='/deputies' />
                    </motion.div>
                )}
                {activeTab === 4 && (
                    <motion.div {...countyPageContentMotion}>
                        <CountyEntityFilterTable url='/aldermen' />
                    </motion.div>
                )}
                {activeTab === 5 && (
                    <motion.div {...countyPageContentMotion}>
                        <ExPresidentsFilterTable />
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
}

export default CountyPage;
