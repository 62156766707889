import { ICountyEntityDTO } from 'DTOModels/countyEntityDTO';

export enum ModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface IEditCountyEntityModalModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: ModalMode;
    item?: ICountyEntityDTO;
    updateList: () => void;
    url: string;
}

export interface IEditCountyEntityModalForm {
    name?: string;
    photo?: any;
    description?: string;
}
