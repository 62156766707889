import { IPresidentDTO } from 'DTOModels/presidentDTO';

export enum ModalMode {
    Edit = 'Edit',
    Create = 'Create',
}

export interface IEditCurrentPresidentModalProps {
    modalVisibility: boolean;
    close: () => void;
    mode?: ModalMode;
    item?: IPresidentDTO;
    updateList: () => void;
}

export interface IEditCurrentPresidentForm {
    name?: string;
    mandateStartDate: moment.Moment | null;
    photo?: any;
    description?: string;
}
