import React, { useEffect, useState } from 'react';
import { Input, Modal, PhoneInput, TextArea, useForm } from 'linkwithus';
import './styles.scss';
import { motion } from 'framer-motion';
import config from 'config';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SiteButton from 'components/SiteButton';
import FileUploader from 'components/FileUploader';
import { phoneRegex } from 'utils/regex';
import {
    TravelAgencyModalMode,
    ITravelAgencyForm,
    ITravelAgencyModalProps,
} from './interface';
import { contentSettingsModalMotion } from './animation';

function TravelAgencyModal({
    modalVisibility,
    close,
    item,
    mode = TravelAgencyModalMode.Edit,
    updateList,
}: ITravelAgencyModalProps) {
    const { t } = useTranslation();
    const [imageUrl, setImageUrl] = useState<string>();
    const [imageVisibility, setImageVisibility] = useState(false);

    const { register, form, submit, updateForm, setForm } =
        useForm<ITravelAgencyForm>({
            url: `/travel/${
                mode === TravelAgencyModalMode.Edit && item ? item.Id : ''
            }`,
            type: mode === TravelAgencyModalMode.Edit ? 'PUT' : 'POST',
            onSuccess: () => {
                notification.success({
                    message: t('general.SUCCESS'),
                    description: t(
                        `contentSettingsPage.${
                            mode === TravelAgencyModalMode.Edit
                                ? 'TRAVEL_AGENCY_EDITED_WITH_SUCCESS'
                                : 'TRAVEL_AGENCY_CREATED_WITH_SUCCESS'
                        }`,
                    ),
                });
                updateList();
                close();
            },
            onError: (data, error: any) => {
                const errorCode = Number(error.errorCode) || 0;
                notification.error({
                    message: t(`general.ERROR`),
                    description: t(`errors.${errorCode}`),
                });
                updateList();
                close();
            },
            resolveData(rawForm) {
                const data = new FormData();
                Object.keys(rawForm).forEach(key => {
                    if (key === 'photo') {
                        rawForm.photo.forEach((f: any) => {
                            data.append('photo', f as any);
                        });
                    } else
                        data.append(
                            key,
                            rawForm[key as keyof ITravelAgencyForm] as any,
                        );
                });
                return data;
            },
        });

    useEffect(() => {
        if (modalVisibility && mode === TravelAgencyModalMode.Edit && !item) {
            close();
        }
        if (modalVisibility && mode === TravelAgencyModalMode.Edit && item) {
            const currentDate = new Date();
            updateForm('name', item.Travelagencylanguages[0].Name);
            updateForm('location', item.Travelagencylanguages[0].Location);
            updateForm('contact', item.Contact);
            setImageUrl(
                `${config.API_URL}/travel/images/${item.Id}?date=${currentDate}`,
            );
            setTimeout(() => {
                setImageVisibility(true);
            }, 100);
        }
        if (!modalVisibility) {
            setForm({});
            setImageVisibility(false);
        }
        if (modalVisibility && mode === TravelAgencyModalMode.Create) {
            setImageUrl(undefined);
            setTimeout(() => {
                setImageVisibility(true);
            }, 100);
        }
    }, [modalVisibility]);

    return (
        <Modal
            visible={modalVisibility}
            close={close}
            className='travel-agency-modal'
            width={400}
        >
            <motion.div
                {...contentSettingsModalMotion}
                className='travel-agency-modal__content'
            >
                <p className='travel-agency-modal__content__title'>
                    {t(
                        `contentSettingsPage.${
                            mode === TravelAgencyModalMode.Edit
                                ? 'EDIT_TRAVEL_AGENCY'
                                : 'CREATE_TRAVEL_AGENCY'
                        }`,
                    )}
                </p>
                <div className='travel-agency-modal__content__body'>
                    <p className='travel-agency-modal__content__body__label'>
                        {t('contentSettingsPage.RENT_A_CAR_NAME')}
                    </p>
                    <Input
                        {...register('name', {
                            required: true,
                            resolveError: () =>
                                `${t('form.NAME')} ${t('form.REQUIRED_FIELD')}`,
                        })}
                        className='travel-agency-modal__content__body__field'
                    />
                    <p className='travel-agency-modal__content__body__label'>
                        {t('form.CONTACT')}
                    </p>
                    <PhoneInput
                        {...register('contact', {
                            required: true,
                            regex: phoneRegex,
                            resolveError: (val, error) => {
                                if (error.type === 'regex') {
                                    return `${t('general.THE')} ${t(
                                        'form.CONTACT',
                                    )} ${t('form.FORMAT_INVALID')}`;
                                }
                                return `${t('form.CONTACT')} ${t(
                                    'form.REQUIRED_FIELD',
                                )}`;
                            },
                        })}
                        className='travel-agency-modal__content__body__field'
                    />
                    <p className='travel-agency-modal__content__body__label'>
                        {t('contentSettingsPage.RENT_A_CAR_DESCRIPTION')}
                    </p>
                    <TextArea
                        {...register('location', {
                            required: true,
                            resolveError: () =>
                                `${t('form.LOCATION')} ${t('form.REQUIRED')}`,
                        })}
                        autoSize
                        className='travel-agency-modal__content__body__field'
                    />
                    {imageVisibility && (
                        <FileUploader
                            {...register('photo')}
                            initial={imageUrl}
                            className='rectangular'
                        />
                    )}
                </div>
                <div className='travel-agency-modal__content__footer'>
                    <SiteButton
                        text={t('general.CANCEL')}
                        type='primary'
                        className='travel-agency-modal__content__footer__button grey'
                        onClick={close}
                    />
                    <SiteButton
                        text={t('form.SAVE_CHANGES')}
                        type='primary'
                        className='travel-agency-modal__content__footer__button'
                        onClick={submit}
                        disabled={
                            mode === TravelAgencyModalMode.Create &&
                            (!form.name ||
                                !form.location ||
                                !form.photo ||
                                !form.contact ||
                                (form.contact &&
                                    !phoneRegex.test(form.contact)) ||
                                (form.photo &&
                                    Array.isArray(form.photo) &&
                                    !(form.photo.length > 0)))
                        }
                    />
                </div>
            </motion.div>
        </Modal>
    );
}

export default TravelAgencyModal;
