export enum IOrderFields {
    NameAndEmail = 'InvitedEmail',
    CreationDate = 'CreationDate',
    IsActive = 'IsActive',
    IsLimited = 'InvitedAccess',
}

export interface IDeleteInviteForm {
    userId?: number;
}
